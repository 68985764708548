/**
 * @description Applies specific opacity toa given element based on a condition.
 * @param {boolean} isVisible flag to know if opacity should be 0 or 1.
 * @param {HTMLElement} element to apply the changes of opacity.
 */
const validateVisibility = (isVisible, element) => {
  if (isVisible) {
    element.style.opacity = 1
  } else {
    element.style.opacity = 0
  }
}

/**
 * @description Directive to changes opacity to make an element visible or not.
 */
export default {
  beforeMount (element, binding) {
    validateVisibility(binding.value, element)
  },
  updated (element, binding) {
    validateVisibility(binding.value, element)
  }
}
