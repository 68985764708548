import { AbilityBuilder, Ability } from '@casl/ability'

const defineAbilitiesFor = (role) => {
  const { can, build } = new AbilityBuilder(Ability)

  if (role.includes('Internal')) {
    can('create', 'WorkItem')
    can('add', 'WorkPortfolio')
    can('delete', 'WorkPortfolio')
    can('edit', 'WorkItem')
    can('delete', 'WorkItem')
    can('download', 'WorkItem')

    can('create', 'IntelItem')
    can('add', 'IntelPortfolio')
    can('delete', 'IntelPortfolio')
    can('edit', 'IntelItem')
    can('delete', 'IntelItem')

    can('add', 'SearchPortfolio')
    can('delete', 'SearchPortfolio')
  }

  return build()
}

export default defineAbilitiesFor
