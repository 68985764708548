/**
 * @description Const names to call the mutation
 * @requires UpperCase all names
 */

export const CHECK_IS_MOBILE = 'CHECK_IS_MOBILE'
export const GET_DATA_USER = 'GET_DATA_USER'
export const TOOLBAR_LOADED = 'TOOLBAR_LOADED'
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const GET_RESULTS_SEARCH = 'GET_RESULTS_SEARCH'
export const TOGGLE_SHOW_DATA = 'TOGGLE_SHOW_DATA'
export const SET_PAGINATION = 'SET_PAGINATION'
export const CLEAN_PAGINATION = 'CLEAN_PAGINATION'
export const GET_FILTERS = 'GET_FILTERS'
export const GET_FILTER_CATEGORIES = 'GET_FILTER_CATEGORIES'
export const SELECT_CATEGORY_FILTER = 'SELECT_CATEGORY_FILTER'
export const TOGGLE_VISIBLE_CONTENT = 'TOGGLE_VISIBLE_CONTENT'
export const TOGGLE_API_LOADING = 'TOGGLE_API_LOADING'
export const UPDATE_FINAL_FILTERS = 'UPDATE_FINAL_FILTERS'
export const UPDATE_SELECTED_FILTERS = 'UPDATE_SELECTED_FILTERS'
export const UPDATE_QUERY_FILTER = 'UPDATE_QUERY_FILTER'
export const TOGGLE_MOBILE_RESOLUTION = 'TOGGLE_MOBILE_RESOLUTION'
export const SET_SOURCE_TO_SEARCH = 'SET_SOURCE_TO_SEARCH'
export const UPDATE_URL = 'UPDATE_URL'
export const REMOVE_QUERY_FILTER = 'REMOVE_QUERY_FILTER'
export const UPDATE_TEMP_QUERY_FILTER = 'UPDATE_TEMP_QUERY_FILTER'
export const GET_DATA_TOOLBAR = 'GET_DATA_TOOLBAR'
export const UPDATE_FILTER_VERSION = 'UPDATE_FILTER_VERSION'
export const SET_EXACT_SEARCH = 'SET_EXACT_SEARCH'
export const SET_SPELL_SUGGESTION = 'SET_SPELL_SUGGESTION'
export const BUILD_QUERY_FILTER = 'BUILD_QUERY_FILTER'
export const CHANGE_DEFAULT_FLOW = 'CHANGE_DEFAULT_FLOW'
export const SET_TRACKING_ID = 'SET_TRACKING_ID'
export const SHOW_MAINTENANCE_MODE = 'SHOW_MAINTENANCE_MODE'
export const SET_REACTIONS = 'SET_REACTIONS'
export const UPDATE_COMMENTS_AMOUNT = 'UPDATE_COMMENTS_AMOUNT'
export const UPDATE_LIKES = 'UPDATE_LIKES'
export const UPDATE_VIEWS = 'UPDATE_VIEWS'
export const SORT_TYPE = 'SORT_TYPE'
export const GET_COLLECTIONS_MINE = 'GET_COLLECTIONS_MINE'
export const ADD_REMOVE_ITEM_FROM_COLLECTION = 'ADD_REMOVE_ITEM_FROM_COLLECTION'
export const SET_COLLECTION_INFO = 'SET_COLLECTION_INFO'
export const SET_CURRENT_SITE = 'SET_CURRENT_SITE'
export const INTELL_SITE = 'INTELL_SITE'
export const SEARCH_SITE = 'SEARCH_SITE'
export const WORK_SITE = 'WORK_SITE'
export const SALES_FORCE_SITE = 'SALES_FORCE_SITE'
export const SET_CARD_IDS_LOADING = 'SET_CARD_IDS_LOADING'
export const TOGGLE_CARDS_SELECTION = 'TOGGLE_CARDS_SELECTION'
export const SET_REFRESH_CARDS_FLAG = 'SET_REFRESH_CARDS_FLAG'
export const TOGGLE_CARD_SELECTION = 'TOGGLE_CARD_SELECTION'
export const SET_REACTIONS_DETAIL = 'SET_REACTIONS_DETAIL'
export const SET_THREE_DOTS_MENU_STATE_BY_ITEM = 'SET_THREE_DOTS_MENU_STATE_BY_ITEM'
export const GET_MANAGER_INFO = 'GET_MANAGER_INFO'
export const TOGGLE_ACTIVE_TOUR = 'TOGGLE_ACTIVE_TOUR'
export const TOGGLE_TOUCHED_STATUS_WORK_STEP = 'TOGGLE_TOUCHED_STATUS_WORK_STEP'
export const IS_SELECT_ALL_ACTIVE = 'IS_SELECT_ALL_ACTIVE'
export const TOGGLE_MANDATORY_FIELDS_STATUS = 'TOGGLE_MANDATORY_FIELDS_STATUS'
export const DATA_USER_LOGIN = 'DATA_USER_LOGIN'
export const ADD_FILE_TO_UPLOAD_LIST = 'ADD_FILE_TO_UPLOAD_LIST'
export const TOGGLE_FILE_VISIBILITY_ON_WIDGET = 'TOGGLE_FILE_VISIBILITY_ON_WIDGET'
export const TOGGLE_NOTIFICATION_WIDGET_FILE = 'TOGGLE_NOTIFICATION_WIDGET_FILE'
export const UPDATE_PROGRESS_UPLOAD_FILE = 'UPDATE_PROGRESS_UPLOAD_FILE'
export const SET_SEARCH_INFORMATION = 'SET_SEARCH_INFORMATION'
export const SET_SEARCH_SAVED_INFORMATION = 'SET_SEARCH_SAVED_INFORMATION'
export const CHECK_IS_SAVED_SEARCH_MOBILE = 'CHECK_IS_SAVED_SEARCH_MOBILE'
export const SET_SEARCH_EDITING_INFORMATION = 'SET_SEARCH_EDITING_INFORMATION'
export const SET_SEARCH_EDITING_HAS_CHANGED = 'SET_SEARCH_EDITING_HAS_CHANGED'
export const SET_SAVED_SEARCHES_PAGINATION = 'SET_SAVED_SEARCHES_PAGINATION'
export const UPDATE_SAVED_SEARCHES_URL = 'UPDATE_SAVED_SEARCHES_URL'
export const SHOWFOOTER = 'SHOWFOOTER'
export const SET_METADATA = 'SET_METADATA'
export const SET_METADATA_INDUSTRIES = 'SET_METADATA_INDUSTRIES'
export const SET_DEFAULT_VALUES = 'SET_DEFAULT_VALUES'
export const SET_DATA_READY = 'SET_DATA_READY'
export const UPDATE_FILE_INFO = 'UPDATE_FILE_INFO'
export const DELETE_FILE = 'DELETE_FILE'
export const SET_WORK_METADATA = 'SET_WORK_METADATA'
export const SET_INITIAL_INDUSTRIES = 'SET_INITIAL_INDUSTRIES'
export const SET_CATEGORY_SELECTED = 'SET_CATEGORY_SELECTED'
export const SET_AWARD_INDEX = 'SET_AWARD_INDEX'
export const SET_THUMBNAIL_INFO = 'SET_THUMBNAIL_INFO'
export const UPDATE_THUMBNAIL_INFO = 'UPDATE_THUMBNAIL_INFO'
export const SET_GRID_VIEW = 'SET_GRID_VIEW'
export const TOGGLE_TABLET_RESOLUTION = 'TOGGLE_TABLET_RESOLUTION'
export const SAVE_UPLOAD_FILE = 'SAVE_UPLOAD_FILE'
export const SET_COLLECTION_PAGINATION = 'SET_COLLECTION_PAGINATION'
export const UPDATE_DETAIL_COLLECTION_URL = 'UPDATE_DETAIL_COLLECTION_URL'
export const SET_SHARING_TOKEN = 'SET_SHARING_TOKEN'
export const UPDATE_COLLECTIONS_PAGE_URL = 'UPDATE_COLLECTIONS_PAGE_URL'
export const SET_MY_REQUESTS_PAGINATION = 'SET_MY_REQUESTS_PAGINATION'
export const SET_MY_REQUESTS_PAGINATION_ITEMS = 'SET_MY_REQUESTS_PAGINATION_ITEMS'
export const UPDATE_MY_REQUESTS_URL = 'UPDATE_MY_REQUESTS_URL'
export const CLEAR_CARDS_IDS = 'CLEAR_CARDS_IDS'
export const CLEAR_UPLOAD_FILES = 'CLEAR_UPLOAD_FILES'
export const SET_TRANSACTION_ID = 'SET_TRANSACTION_ID'
export const SET_SUPPORTING_EXTRA_IMAGE_ID = 'SET_SUPPORTING_EXTRA_IMAGE_ID'
export const SET_SUPPORTING_FILES_IN_PROCESS = 'SET_SUPPORTING_FILES_IN_PROCESS'
export const SET_SALES_FORCE_ITEMS = 'SET_SALES_FORCE_ITEMS'
export const SET_TOASTS_ARRAY = 'SET_TOASTS_ARRAY'
export const REMOVE_TOAST = 'REMOVE_TOAST'
export const REMOVE_NEXT_TOASTS = 'REMOVE_NEXT_TOASTS'
export const SET_SELECT_THUMBNAIL_INFO = 'SET_SELECT_THUMBNAIL_INFO'
export const SET_THUMBNAIL_SELECTED = 'SET_THUMBNAIL_SELECTED'
export const SET_COLLECTIONS_ABORT_SIGNAL = 'SET_COLLECTIONS_ABORT_SIGNAL'
export const UPDATE_NOTIFICATIONS_URL = 'UPDATE_NOTIFICATIONS_URL'
export const SET_NOTIFICATIONS_PAGINATION = 'SET_NOTIFICATIONS_PAGINATION'
export const TOGGLE_MOBILE_HIGHLIGHT_MODAL = 'TOGGLE_MOBILE_HIGHLIGHT_MODAL'
export const TOGGLE_NOTIFICATION_SELECTION = 'TOGGLE_NOTIFICATION_SELECTION'
export const TOGGLE_ALL_NOTIFICATIONS_SELECTION = 'TOGGLE_ALL_NOTIFICATIONS_SELECTION'
export const SELECT_ALL_NOTIFICATIONS_ACTIVE = 'SELECT_ALL_NOTIFICATIONS_ACTIVE'
export const CLEAN_NOTIFICATIONS_IDS_TO_ACTION = 'CLEAN_NOTIFICATIONS_IDS_TO_ACTION'
export const TOGGLE_ALL_COLLECTION_ITEMS_SELECTION = 'TOGGLE_ALL_COLLECTION_ITEMS_SELECTION'
export const TOGGLE_COLLECTION_ITEM_SELECTION = 'TOGGLE_COLLECTION_ITEM_SELECTION'
export const SET_SOURCE_TO_NOTIFICATIONS = 'SET_SOURCE_TO_NOTIFICATIONS'
export const SET_STATUS_TO_NOTIFICATIONS = 'SET_STATUS_TO_NOTIFICATIONS'
