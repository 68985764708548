import { createStore } from 'vuex'
import axios from 'axios'
import * as types from './mutationTypes'
import moment from 'moment'
import router from '@/router'
import storeConstants from './store.json'
import workModule from './workModule'
import formModule from './formModule'
import collectionsModule from './collectionsModule'
import VueJwtDecode from 'vue-jwt-decode'
import defineAbilitiesFor from './abilities'

const state = {
  dataUserLogin: {},
  toolbarLoaded: false,
  showFooter: true,
  isMobile: [],
  isMobileResolution: false,
  searchValue: '',
  isThereSearch: '',
  dataCards: [],
  totalResultsCount: 0,
  currentYear: new Date().getFullYear(),
  searchSource: '',
  showData: null,
  pagination: {
    page: 1,
    size: 16
  },
  filters: {
    filterList: [],
    filterCategories: [],
    filterByCategory: {},
    selectedCategory: null,
    temporaryFilters: [],
    selectedFilters: [],
    queryFilter: null,
    queryBetweenFilters: [],
    filterVersion: 0
  },
  hideMainContent: false,
  isAPILoading: false,
  source: 'All sites',
  defaultFlow: '',
  intelligence: {
    metadata: null,
    brands: null
  },
  accessFileConf: {},
  spellSuggestion: {
    newSpell: '',
    originalResults: false,
    newSpellNumber: null
  },
  exactSearch: false,
  trackingSearchId: '',
  maintenanceMode: false,
  reactions: {},
  sortType: {
    parameter: null,
    order: null
  },
  collectionsMine: {},
  collectionsInfo: {
    isCollectionModalOpened: false,
    cardId: ''
  },
  currentSite: null,
  cardsSelectedIds: {},
  cardIdsLoading: false,
  refreshCardsIdsNeeded: true,
  counterSelectedCards: 0,
  areAllCardsSelected: false,
  threeDotsMenu: '',
  managerInfo: {},
  activeTours: {
    GSV_feedbackButton: {
      isPending: false,
      isRenderReady: false,
      onlyDesktop: false
    },
    GSV_threeDotsCardMenu: {
      isPending: false,
      isRenderReady: false,
      onlyDesktop: true
    }
  },
  saveSearch: {
    searchMade: false,
    searchQuery: '',
    searchFilters: '',
    newSearchQuery: {},
    searchSaved: false,
    searchId: '',
    isEditingSearch: false,
    editingSearchHasChanged: false,
    editingSearchInfo: {
      name: '',
      source: '',
      searchTerms: '',
      searchFilters: [],
      orderBy: ''
    }
  },
  phoneResolution: false,
  saveSearchPagination: {
    page: 1,
    size: 12,
    maxPages: 6
  },
  collectionPagination: {
    page: 1,
    size: 48
  },
  abilities: null,
  isGridView: false,
  isTabletResolution: false,
  file: {},
  sharingToken: null,
  myRequestPagination: {
    page: 1,
    size: 12,
    maxPages: 6,
    items: []
  },
  salesForceInformation: {
    name: '',
    itemIds: []
  },
  currentToasts: [],
  nextToasts: [],
  notificationsPagination: {
    page: 1,
    size: 12
  },
  isHighLightModalMobile: false,
  counterSelectedNotifications: 0,
  areAllNotificationsSelected: false,
  notificationsIdsToAction: [],
  selectAllNotificationsChecked: false,
  collectionItemsIds: [],
  collectionItemsIdsSelected: [],
  sourceNotifications: 'All',
  statusNotifications: 'All'
}

const helpers = {

  /**
   * @description Builds the query for filters based on an array of filters.
   * @param currentFilters array of filters used to build the query string for filters.
   */
  getQueryForFilters (currentFilters) {
    const extractValuesByCategory = (category) => {
      return currentFilters.reduce((obj, filter) => {
        if (filter.category === category) { obj.push(filter.description) }
        return obj
      }, [])
    }

    const createArrayFilters = (selectedFilters) => {
      return selectedFilters.reduce((obj, filter) => {
        if (!obj.includes(filter.category)) { obj.push(filter.category) }
        return obj
      }, [])
    }

    const createFiltersObject = (allCategories) => {
      return allCategories.map(category => {
        const result = {
          field: category,
          values: extractValuesByCategory(category)
        }
        return result
      })
    }

    const createFullFilters = filters => createFiltersObject((createArrayFilters(filters)))
    const finalFilters = createFullFilters(currentFilters)
    return finalFilters
  },

  /**
   * @description Scape special characters from a given string.
   * @param {specialChars} array with all special chars to be scaped from string.
   * @param {unformattedString} string to be formatted.
   */
  scapeSpecialChars (specialChars, unformattedString) {
    let formatedString = unformattedString
    specialChars.forEach((specialChar) => {
      formatedString = formatedString.replace(new RegExp(specialChar, 'g'), storeConstants.scapedChars[specialChar])
    })
    return formatedString
  },

  /**
   * @description Validate if two arrays are different
   * @param {first} first first array
   * @param {second} second second array
   */
  arrayDifferents (first, second) {
    let arrayDifferents = false
    if (first.length !== second.length) {
      arrayDifferents = true
    } else {
      for (const itemFirst of first) {
        const itemContained = second.some(itemSecond => {
          return itemSecond.category === itemFirst.category && itemSecond.description === itemFirst.description
        })
        if (!itemContained) {
          arrayDifferents = true
          break
        }
      }
    }
    return arrayDifferents
  }
}

const getters = {

  /**
   * @description returns date with format 'Mmm DD, YYYY'.
   * @param date date to format.
   */
  formatDate: (state) => (date) => {
    return moment(date).format('ll')
  },

  /**
   * @description return date formatted
   * @param date Date
   */
  formatStartDate: (state) => (date) => {
    return moment(date).format('DD MMM')
  },

  /**
   * @description returns a formated string for the size of a file given in bytes.
   * @param sizeOnBytes size to format given in bytes.
   */
  formatSizeFile: (state) => (sizeOnBytes) => {
    const suffixes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (sizeOnBytes === 0) {
      return '0 Bytes'
    }
    const power = parseInt(Math.floor(Math.log(sizeOnBytes) / Math.log(1024)))
    return `${parseFloat(sizeOnBytes / Math.pow(1024, power)).toFixed(2)} ${suffixes[power]}`
  },

  /**
   * @description Returns an array of visible filters based on category selected.
   */
  visibleFilters: (state) => {
    if (state.filters.selectedCategory) {
      return state.filters.filterByCategory[state.filters.selectedCategory.description]
    }
    return []
  },

  /**
   * @description return search term based on url params.
   * @param url current url.
   */
  searchTermFromUrl: (state) => (url) => {
    let searchValue = url.q
    const isTextWithQuotes = searchValue[0] === '"' && searchValue[searchValue.length - 1] === '"'

    if (isTextWithQuotes) {
      searchValue = searchValue.substring(1, searchValue.length - 1)
    }

    return searchValue
  },

  /**
   * @description Returns the year of a date.
   * @param date date given to extract the year.
   */
  getYear: (state) => (date) => {
    return date ? moment(date).year() : ''
  },

  /**
   * @description Scape special characters from a given string.
   */
  scapeSpecialChars: (state) => helpers.scapeSpecialChars,

  /**
   * @description Update href from event target of elemento of DOM sent.
   * @param eventDom Event of DOM of element to update href
   * @param href Path to assign to event.targe.href from element of DOM
   */
  updateHref: (state) => (eventDom, href) => {
    eventDom.target.href = href
  },

  /**
   * @description Returns an string separated by commas from a given array of strings.
   * @param {array} array of strings to be joined.
   */
  parseArrayToString: (state) => (array) => {
    return array ? array.join(', ') : ''
  },

  /**
   * @description Change UI representation of a category name to use a predefined name.
   * @param {filterCategory} name of the category filter
   */
  getFilterCategoryName: (state) => (filterCategory) => {
    switch (filterCategory) {
      case 'publish_year':
        return 'Publish year'
      case 'persona_title':
        return 'Title'
      case 'content_type':
        return 'Content Type'
      case 'file_type':
        return 'File Type'
      case 'business_region':
        return 'Region'
      case 'asset_type':
        return 'Asset Type'
      case 'asset_status':
        return 'Asset Status'
      case 'qc_status':
        return 'QC Status'
      case 'year_created':
        return 'Year'
      case 'frame_rate':
        return 'Frame rate'
      case 'internal_category':
        return 'File Categories'
      case 'start_year':
        return 'Start Year'
      case 'filter_start_date':
        return 'Start Date'
      case 'opportunity_role':
        return 'Role'
      default:
        return filterCategory
    }
  },

  /**
   * @description Validate if an action can be performed by the current user
   * @param action Action to be performed
   * @param resource Resource of the app
   */
  actionCanBePerformed: (state) => (action, resource) => {
    return state.abilities.can(action, resource)
  },

  /**
   * @description Gets the query to filter the results based on the filters selected.
   * @param {Object} state of the store.
   */
  getQueryFilters: (state) => {
    const finalFilters = helpers.getQueryForFilters(state.filters.selectedFilters)
    return finalFilters
  },

  /**
   * @description Get the formatted date difference between a given date and current time
   * @param date Date to compare with current time
   */
  formatTimeAgo: (state, getters) => (date) => {
    const previousDate = moment(date)
    const currentDate = moment()
    const intervals = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
    const preciseDateDiff = {}

    for (let index = 0; index < intervals.length; index++) {
      const difference = currentDate.diff(previousDate, intervals[index])
      preciseDateDiff[intervals[index]] = difference
      previousDate.add(difference, intervals[index])
    }

    return getters.dateDiffPersonalized(preciseDateDiff)
  },

  /**
   * @description Format intervals date difference in a personalized way
   * @param dateDiff Object with intervals date difference
   */
  dateDiffPersonalized: (state, getters) => (dateDiff) => {
    let diffFormatted = ''
    if (dateDiff.years && dateDiff.months) {
      diffFormatted = getters.formatLabel(dateDiff.years, 'year') + ', ' + getters.formatLabel(dateDiff.months, 'month')
    } else if (dateDiff.months) {
      diffFormatted = getters.formatLabel(dateDiff.months, 'month')
    } else if (dateDiff.weeks) {
      diffFormatted = getters.formatLabel(dateDiff.weeks, 'week')
    } else if (dateDiff.days) {
      diffFormatted = dateDiff.days > 1 ? getters.formatLabel(dateDiff.days, 'day') : 'Yesterday'
    } else if (dateDiff.hours) {
      diffFormatted = getters.formatLabel(dateDiff.hours, 'hour')
    } else if (dateDiff.minutes) {
      diffFormatted = getters.formatLabel(dateDiff.minutes, 'minute')
    } else {
      diffFormatted = getters.formatLabel(dateDiff.seconds, 'second')
    }
    return diffFormatted + (diffFormatted === 'Yesterday' ? '' : ' ago')
  },

  /**
   * @description return date formatted
   * @param previousDate Date
   */
  formatDatePublish: (state) => (previousDate) => {
    let formattedPublishDate = ''
    const currentDate = moment()
    const daysDifference = currentDate.diff(previousDate, 'days')
    switch (daysDifference) {
      case 0:
        formattedPublishDate = 'Today'
        break
      case 1:
        formattedPublishDate = 'Yesterday'
        break
      default:
        formattedPublishDate = daysDifference < 30 ? `${daysDifference} days ago` : moment(previousDate).format('MM/DD/YYYY')
        break
    }
    return formattedPublishDate
  },

  /**
   * @description Get label information about date difference
   * @param number Number of interval
   * @param singularLabel Name of the interval
   */
  formatLabel: (state) => (number, singularLabel) => {
    return number + ' ' + singularLabel + (number > 1 ? 's' : '')
  },

  /**
   * @description Changes order of author name to be firstname and lastname
   * @param name to format
   */
  formatAuthorName: (state) => (name) => {
    let formattedName
    if (name && name.includes(',')) {
      const nameWithoutComma = name.replace(',', '')
      formattedName = nameWithoutComma.split(' ').reverse().join(' ')
    }
    return formattedName || name
  },

  /**
   * @description returns string whitout whitespaces
   * @param text text to remove whitespaces
   */
  removeWhitespaces: (state) => (text) => {
    return text.trim()
  },

  /**
  * @description Indicates if current user logged in is the same as a given email
  * @param email email to verify.
  */
  isUserLogged: (state) => (email) => {
    return state.dataUserLogin.email === email
  },

  /**
  * @description Gets the iOS version of iphone
  */
  iOSVersion: (state) => () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return userAgent.includes('iphone') && Number(userAgent.substr(userAgent.indexOf('version/') + 8, 2))
  }
}

const mutations = {
  /**
   * @description Check if the user is logged since any desktop or mobile
   */
  [types.CHECK_IS_MOBILE] (state) {
    state.isMobile = navigator.userAgent.match(/Android|iPhone|iPad/i)
  },

  /**
   * @description Save user data login
   * @param payload Payload
   * @param {payload} data Info user logged
   */
  [types.DATA_USER_LOGIN] (state, payload) {
    const personalData = VueJwtDecode.decode(localStorage.getItem('sessionToken'))
    state.dataUserLogin.email = personalData.email
    state.dataUserLogin.name = personalData.unique_name
    state.dataUserLogin.ecCode = personalData.eccode
    state.dataUserLogin.isAuthenticated = true
    state.dataUserLogin.role = personalData.role
    state.abilities = defineAbilitiesFor(state.dataUserLogin.role)
  },

  /**
   * @description Toolbar loaded
   * @param {payload} loaded boolean
   */
  [types.TOOLBAR_LOADED] (state, payload) {
    state.toolbarLoaded = payload.loaded
  },

  /**
   * @description Set the new search value to the store
   * @param {Payload} searchValue the new search value
   */
  [types.SET_SEARCH_VALUE] (state, Payload) {
    state.isThereSearch = Payload.searchValue
    if (Payload.apply) {
      state.searchValue = Payload.searchValue
    }
  },

  /**
   * @description Clear search
   */
  [types.CLEAR_SEARCH] (state) {
    state.searchValue = state.isThereSearch = ''
  },

  /**
   * @description Save results data
   * @param {payload} dataCards Array with all data
   */
  [types.GET_RESULTS_SEARCH] (state, payload) {
    state.dataCards = payload.dataCards
    state.totalResultsCount = payload.dataCards['@odata.count']
  },

  /**
   * @description Toggles visibility of data.
   * @param {payload}  toggle true or false.
   */
  [types.TOGGLE_SHOW_DATA] (state, payload) {
    state.showData = payload.toggle
  },

  /**
   * @description Set number of page and size
   * @param {payload} size number of results
   * @param {payload} page number of page
   */
  [types.SET_PAGINATION] (state, payload) {
    state.pagination.size = payload.size || state.pagination.size
    state.pagination.page = payload.page || state.pagination.page
  },

  /**
   * @description Restart pagination
   * @param {payload} size number of results
   * @param {payload} page number of page
   */
  [types.CLEAN_PAGINATION] (state, payload) {
    state.pagination.size = payload.size || state.pagination.size
    state.pagination.page = 1
  },

  /**
   * @description Toggles a flag indicating if user is waiting for an API response.
   * @param {state} state of store.
   * @param {payload} isAPILoading flag to know if app is waiting for an API response.
   */
  [types.TOGGLE_API_LOADING] (state, payload) {
    state.isAPILoading = payload.isAPILoading
  },

  /**
   * @description Toggles visibility of main content (util to show modal that use whole screen)
   * @param {state} state of store.
   * @param {payload} hideMainContent flag to indicate if main content should be hidden.
   */
  [types.TOGGLE_VISIBLE_CONTENT] (state, payload) {
    state.hideMainContent = payload.hideMainContent
  },

  /**
   * @description Updates value of selected filters based on user selection.
   * @param {state} state of store.
   * @param {payload} selectedFilters array with selected filters.
   */
  [types.UPDATE_FINAL_FILTERS] (state, payload) {
    state.filters.selectedFilters = payload.selectedFilters.slice(0)
  },

  /**
   * @description Updates value of temporary filters based on the previously selected.
   * @param {state} state of store.
   * @param {payload} temporaryFilters array with temporary filters selected by user.
   */
  [types.UPDATE_SELECTED_FILTERS] (state, payload) {
    state.filters.temporaryFilters = payload.temporaryFilters.slice(0)
  },

  /**
   * @description Parses response got from API to get information about filters to show in the UI.
   * @param {state} state of store.
   * @param {payload} filters array with filters got from API.
   */
  [types.GET_FILTERS] (state, payload) {
    state.filters.filterList = payload.filters.map(category => {
      state.filters.filterByCategory[category.name] = []

      return category.values.map(filter => {
        const filterElement = {
          description: filter.name,
          total: filter.count,
          category: category.name
        }

        state.filters.filterByCategory[category.name].push(filterElement)

        return filterElement
      })
    }).flat(2)
  },

  /**
   * @description Gets parsed categories from API.
   * @param {state} state of store.
   * @param {payload} filters array with filters got from API.
   */
  [types.GET_FILTER_CATEGORIES] (state, payload) {
    state.filters.filterCategories = payload.filters.map(category => {
      return {
        description: category.name,
        total: category.count,
        isSelected: false
      }
    })
  },

  /**
   * @description Marks a category as selected.
   * @param {state} state of store.
   * @param {payload} selectedIndex index of the selected category.
   * @param {payload} keepCategory flag to indicate if category should keep.
   */
  [types.SELECT_CATEGORY_FILTER] (state, payload) {
    let finalIndex = 0
    if (payload.selectedIndex === undefined && state.filters.temporaryFilters.length) {
      const lastItemArray = state.filters.temporaryFilters[state.filters.temporaryFilters.length - 1]

      finalIndex = state.filters.filterCategories.findIndex(value => value.description === lastItemArray.category)
    }

    if (payload.keepCategory) {
      finalIndex = state.filters.filterCategories.findIndex(value => value.description === state.filters.selectedCategory.description)
    }

    const selectedIndex = payload.selectedIndex || finalIndex
    state.filters.filterCategories = state.filters.filterCategories.map((category, index) => {
      category.isSelected = index === selectedIndex

      if (category.isSelected) {
        state.filters.selectedCategory = Object.assign({}, category)
      }

      return category
    })
  },

  /**
   * @description Updates query used to apply filters to search
   * @param {state} state of store.
   * @param {payload} queryFilter string with query to be used on the url.
   */
  [types.UPDATE_QUERY_FILTER] (state, payload) {
    state.filters.queryFilter = payload.queryFilter
  },

  /**
   * @description Updates store flag that indicates if user is using a mobile resolution.
   * @param {state} state of store.
   */
  [types.TOGGLE_MOBILE_RESOLUTION] (state) {
    state.isMobileResolution = window.innerWidth < 768
  },

  /**
   * @description Updates store flag that indicates if user is using a tablet resolution.
   * @param {state} state of store.
   */
  [types.TOGGLE_TABLET_RESOLUTION] (state) {
    state.isTabletResolution = window.innerWidth < 1220
  },

  /**
   * @description Set source to search in API
   * @param {state} state of store.
   * @param {payload} source Source selected by user
   */
  [types.SET_SOURCE_TO_SEARCH] (state, payload) {
    state.source = payload.source
    switch (payload.source.toLowerCase()) {
      case 'all sites':
        state.searchSource = ''
        break
      case 'intelligence':
        state.searchSource = 'intelligence'
        break
      case 'people':
        state.searchSource = 'persona'
        break
      case 'news':
        state.searchSource = 'news'
        break
      case 'work':
        state.searchSource = 'work'
        break
      case 'office365':
        state.searchSource = 'sharepoint'
        break
      case 'go grey':
        state.searchSource = 'gogrey'
        break
    }
  },

  /**
   * @description Set source to GET notifications
   * @param {state} state of store.
   * @param {payload} source Source selected by user
   */
  [types.SET_SOURCE_TO_NOTIFICATIONS] (state, payload) {
    state.sourceNotifications = payload.source
  },

  /**
   * @description Set status to GET notifications
   * @param {state} state of store.
   * @param {payload} source Source selected by user
   */
  [types.SET_STATUS_TO_NOTIFICATIONS] (state, payload) {
    state.statusNotifications = payload.status
  },

  /**
   *
   * @description Keeping update url
   * @param state of store
   * @param {payload} name of view to redirect
   */
  [types.UPDATE_URL] (state, payload) {
    let filterQuery = ''
    state.filters.temporaryFilters.forEach((filter, key) => {
      filterQuery += `${filter.category}::${filter.description}`
      if (key < state.filters.temporaryFilters.length - 1) {
        filterQuery += ',,'
      }
    })
    const routeQuery = {
      q: state.searchValue ? state.exactSearch ? `"${state.searchValue}"` : state.searchValue : '',
      t: state.pagination.size ? state.pagination.size : '',
      k: state.pagination.page ? state.pagination.page : '',
      s: state.source,
      f: filterQuery,
      o: state.sortType.parameter === '' ? `Relevance ${state.sortType.order}` : `${state.sortType.parameter} ${state.sortType.order}`
    }
    if (state.defaultFlow !== 'salesForce') routeQuery.gv = state.isGridView
    router.push({
      name: payload.name,
      query: routeQuery
    })
  },

  /**
   *
   * @description Remove filters selected or remove all filters whenever user click on clear all button
   * @param {*} state of store
   * @param {payload} item Object with info from item to remove
   */
  [types.REMOVE_QUERY_FILTER] (state, payload) {
    if (payload.item !== undefined) {
      const index = state.filters.temporaryFilters.findIndex((value) => {
        return value.description === payload.item.description && value.category === payload.item.category
      })
      state.filters.temporaryFilters.splice(index, 1)
      return
    }

    state.filters.temporaryFilters = []
  },

  /**
   *
   * @description Keeping updated array filters and prepare string to send request to get filters
   * @param {*} state of store
   * @param {payload} item Object with info from item selected
   */
  [types.UPDATE_TEMP_QUERY_FILTER] (state, payload) {
    if (payload.item) {
      state.filters.temporaryFilters.push(payload.item)
      return
    }

    state.filters.queryBetweenFilters = helpers.getQueryForFilters(state.filters.temporaryFilters)
  },

  /**
   * @description Updates version of filters.
   * @param {*} state of store.
   * @param {payload} version specific version to set to filters.
   */
  [types.UPDATE_FILTER_VERSION] (state, payload) {
    state.filters.filterVersion = payload.version || state.filters.filterVersion + 1
  },

  /**
   * @description Updates value of new spell suggestion.
   * @param {*} state of store.
   * @param {payload} newSpell word suggested by API to make a new search.
   * @param {payload} originalResults check that the original search has results or not
   * @param {payload} newSpellNumber number of results of the new spell
   */
  [types.SET_SPELL_SUGGESTION] (state, payload) {
    state.spellSuggestion.newSpell = payload.newSpell
    state.spellSuggestion.originalResults = payload.originalResults
    state.spellSuggestion.newSpellNumber = payload.newSpellNumber
  },

  /**
   * @description Updates flag to know if search will be made with exact match criteria.
   * @param {*} state of store.
   * @param {payload} exactSearch flag indicating if exact match will be executed in the search.
   */
  [types.SET_EXACT_SEARCH] (state, payload) {
    state.exactSearch = payload.exactSearch
  },

  /**
   *
   * @description Builds and updates query filters based on a given array of filters.
   * @param {*} state of store
   * @param {payload} filters array of filters used to build and update te query filter.
   */
  [types.BUILD_QUERY_FILTER] (state, payload) {
    if (payload && payload.filters) {
      state.filters.queryBetweenFilters =
        helpers.getQueryForFilters(payload.filters)
    }
  },

  /**
   *
   * @description Set the current search id to track in Azure Analytics
   * @param {*} state of store
   * @param {payload} searchId ID for tracking in Azure Analytics
   */
  [types.SET_TRACKING_ID] (state, payload) {
    if (payload && payload.searchId) {
      state.trackingSearchId = payload.searchId
    }
  },

  /**
   * @description Change flow user follow
   * @param {payload} defaultSource If It is empty, is SEARCH, If It is 'intelligence' is other flow
   */
  [types.CHANGE_DEFAULT_FLOW] (state, payload) {
    state.defaultFlow = payload.defaultSource
  },

  /**
   * @description Set the maintenance mode
   */
  [types.SHOW_MAINTENANCE_MODE] (state) {
    state.maintenanceMode = true
  },

  /**
   * @description Sets the object with reactions (likes, views and comments).
   * @param {state} state of store.
   * @param {payload} cards info of cards got from API.
   */
  [types.SET_REACTIONS] (state, payload) {
    state.reactions = payload.cards.reduce((reactions, card) => {
      const configuration = storeConstants.reactionsBySource[card.source]
      reactions[card.id] = {
        views: {
          count: card.views || 0,
          isVisible: configuration && configuration.viewsVisible
        },
        likes: {
          count: card.likes || 0,
          userGaveLike: card.liked_by.includes(payload.email),
          isVisible: configuration && configuration.likeVisible,
          disabled: configuration && configuration.likeDisabled
        },
        comments: {
          count: card.comments || 0,
          isVisible: configuration && configuration.commentsVisible,
          disabled: configuration && configuration.commentsDisabled
        }
      }
      return reactions
    }, {})
  },

  /**
   * @description Updates the commentsAmount in the global storage
   * @param {state} state of store.
   * @param {payload} id String with the ID of the card to modify.
   * @param {payload} commentsAmount Amount of comments.
   */
  [types.UPDATE_COMMENTS_AMOUNT] (state, payload) {
    state.reactions[payload.id].comments.count = payload.commentsAmount
  },

  /**
   * @description Updates the number of likes.
   * @param {state} state of store.
   * @param {payload} id id of the item to change its number of likes.
   * @param {payload} isLike flag indicating if user give or remove like.
   */
  [types.UPDATE_LIKES] (state, payload) {
    state.reactions[payload.id].likes = {
      count: payload.isLike ? state.reactions[payload.id].likes.count + 1 : Math.max(0, state.reactions[payload.id].likes.count - 1),
      userGaveLike: payload.isLike,
      isVisible: state.reactions[payload.id].likes.isVisible,
      showLabel: state.reactions[payload.id].likes.showLabel,
      disabled: state.reactions[payload.id].likes.disabled
    }
  },

  /**
   * @description Updates the number of views.
   * @param {state} state of store.
   * @param {payload} id id of the item to change its number of views.
   * @param {payload} count new number of views to set.
   */
  [types.UPDATE_VIEWS] (state, payload) {
    if (!state.reactions[payload.id]) {
      return
    }

    const views = state.reactions[payload.id].views
    if (payload.count !== views.count) {
      state.reactions[payload.id].views = {
        count: payload.count,
        isVisible: views.isVisible
      }
    }
  },

  /**
   * @description Sets the object with reactions (likes, views and comments).
   * @param {state} state of store.
   * @param {payload} item item to set reactions on.
   */
  [types.SET_REACTIONS_DETAIL] (state, payload) {
    const configuration = storeConstants.reactionsBySource[payload.item.source]
    state.reactions[payload.item.id] = {
      views: {
        count: payload.item.views || 0,
        isVisible: configuration && configuration.viewsVisible
      },
      likes: {
        count: payload.item.likes || 0,
        userGaveLike: payload.item.liked_by.includes(payload.email),
        isVisible: configuration && configuration.likeVisible,
        disabled: configuration && configuration.likeDisabled
      },
      comments: {
        count: payload.item.comments || 0,
        isVisible: configuration && configuration.commentsVisible,
        disabled: configuration && configuration.commentsDisabled
      }
    }
  },

  /**
   * @description Set the sort order of the items in the view
   * @param {state} state of store
   * @param {payload} parameter attribute for what cards are going to be ordered
   * @param {payload} order Type or order (asc or desc)
   */
  [types.SORT_TYPE] (state, payload) {
    state.sortType.parameter = payload.parameter
    state.sortType.order = payload.order
  },

  /**
   *
   * @description Set colecction in array of state
   * @param {state} state of store
   * @param {payload} collectionsMine Array where are colecctions
   * @param {payload} cardIdToAdd Id card to verify if It belongs to some collection and set attribute
   */
  [types.GET_COLLECTIONS_MINE] (state, payload) {
    state.collectionsMine = []
    payload.collectionsMine.forEach((value) => {
      let containId = false
      if (payload.cardsIdToAdd.length === 1) {
        containId = value.itemIds.includes(payload.cardsIdToAdd[0])
      } else {
        containId = !payload.cardsIdToAdd.some((id) => !value.itemIds.includes(id))
      }
      state.collectionsMine.push(Object.assign({ containId: containId }, value))
    })
  },

  /**
   *
   * @description Update reactivity when collection got some change
   * @param {state} state of store
   * @param {payload} positionCollection Position in array where collection changed its state
   */
  [types.ADD_REMOVE_ITEM_FROM_COLLECTION] (state, payload) {
    state.collectionsMine.forEach(
      (value, index) => {
        if (index === payload.positionCollection) {
          value.containId = !value.containId
        }
      }
    )
  },

  /**
   *
   * @description Set config about collections
   * @param {payload} flag true or false to open or hide modal collections
   * @param {payload} id ID from card
   */
  [types.SET_COLLECTION_INFO] (state, payload) {
    state.collectionsInfo.isCollectionModalOpened = payload.flag
    state.collectionsInfo.cardId = payload.id
  },

  /**
   *
   * @description Sets state of cards' ids to know if they are being loaded.
   * @param {state} state of store.
   * @param {payload} cardIdsLoading true or false to know if they are being loaded.
   */
  [types.SET_CARD_IDS_LOADING] (state, payload) {
    state.cardIdsLoading = payload.cardIdsLoading

    if (state.cardIdsLoading) {
      state.counterSelectedCards = 0
      state.cardsSelectedIds = {}
    }
  },

  /**
   *
   * @description Marks all items as selected or deselected.
   * @param {state} state of store.
   * @param {payload} areAllCardsSelected boolean to know if items will be selected or deselected.
   */
  [types.TOGGLE_CARDS_SELECTION] (state, payload) {
    const keys = Object.keys(state.cardsSelectedIds)
    state.cardsSelectedIds = {}
    keys.forEach(id => {
      state.cardsSelectedIds[id] = payload.areAllCardsSelected
    })

    if (payload.areAllCardsSelected) {
      state.counterSelectedCards = Object.keys(state.cardsSelectedIds).length
    } else {
      state.counterSelectedCards = 0
    }
  },

  /**
   *
   * @description Update notification counter when user marks all items as selected or deselected.
   * @param {state} state of store.
   * @param {payload} areAllNotificationsSelected boolean to know if items will be selected or deselected.
   */
  [types.TOGGLE_ALL_NOTIFICATIONS_SELECTION] (state, payload) {
    if (payload.areAllNotificationsSelected) {
      state.counterSelectedNotifications = payload.totalNotifications
    } else {
      state.counterSelectedNotifications = 0
    }
    state.selectAllNotificationsChecked = payload.areAllNotificationsSelected
  },

  /**
   * @description Selects or deselects an specific item.
   * @param {state} state of store.
   * @param {payload} item item to modify.
   * @param {payload} isSelected boolean to know if item will be selected or not.
   */
  [types.TOGGLE_NOTIFICATION_SELECTION] (state, payload) {
    if (payload.isSelected) {
      state.counterSelectedNotifications++
    } else {
      state.counterSelectedNotifications--
    }
    if (state.counterSelectedNotifications === 0) {
      state.selectAllNotificationsChecked = false
    }
    const index = state.notificationsIdsToAction.indexOf(payload.item.id)
    state.notificationsIdsToAction.find(x => x === payload.item.id) && index > -1 ? state.notificationsIdsToAction.splice(index, 1) : state.notificationsIdsToAction.push(payload.item.id)
  },

  /**
   * @description Sets a flag to know if is needed refresh the list of cards' id.
   * @param {state} state of store.
   * @param {payload} refreshCardsIdsNeeded boolean to know if any refresh is needed.
   */
  [types.SET_REFRESH_CARDS_FLAG] (state, payload) {
    state.refreshCardsIdsNeeded = payload.refreshCardsIdsNeeded
  },

  /**
   * @description Selects or deselects an specific item.
   * @param {state} state of store.
   * @param {payload} id string with the id of the item to modify.
   * @param {payload} isSelected boolean to know if item will be selected or not.
   */
  [types.TOGGLE_CARD_SELECTION] (state, payload) {
    const temporalCards = {}
    Object.keys(state.cardsSelectedIds).forEach(id => {
      if (id === payload.id) {
        temporalCards[id] = payload.isSelected
      } else {
        temporalCards[id] = state.cardsSelectedIds[id]
      }
    })

    if (payload.isSelected) {
      state.counterSelectedCards++
    } else {
      state.counterSelectedCards--
    }
    document.getElementsByClassName('containerMobileMenu__menu')[0].style.bottom = state.counterSelectedCards > 0 ? '68px' : '34px'
    state.cardsSelectedIds = temporalCards
  },

  /**
   *
   * @description Set current site
   * @param {state} state  of store
   * @param {payload} site Current site
   */
  [types.SET_CURRENT_SITE] (state, payload) {
    state.currentSite = types[payload.site]
  },

  /**
   * @description Set current 3 dots menu opened
   * @param {*} state of store
   * @param {payload} item Card id
   */
  [types.SET_THREE_DOTS_MENU_STATE_BY_ITEM] (state, payload) {
    state.threeDotsMenu = payload.item === state.threeDotsMenu ? '' : payload.item
  },

  /**
   * @description Set manager Info
   * @param {state} state of store
   * @param {payload} managerInfo Array info
   * @param {payload} namePositionNumber Position in array
   */
  [types.GET_MANAGER_INFO] (state, payload) {
    state.managerInfo = payload.managerInfo
    state.managerInfo.namePositionNumber = payload.namePositionNumber
  },

  /**
   * @description Changes the properties for tours visibility.
   * @param {state} state of store.
   * @param {payload} tourName name of the tour to be shown or hidden.
   * @param {payload} property of the tour to be modified.
   * @param {payload} value to set on the property of the tour object.
   */
  [types.TOGGLE_ACTIVE_TOUR] (state, payload) {
    let value = payload.value
    if (payload.property === 'isPending' && state.activeTours[payload.tourName].onlyDesktop) {
      value = !state.isMobileResolution && payload.value
    }
    state.activeTours[payload.tourName][payload.property] = value
  },

  /**
   * @description Store flag when user select all cards or not.
   * @param {state} state of store
   * @param {payload} areAllCardsSelected flag indicating all cards are selected or no
   */
  [types.IS_SELECT_ALL_ACTIVE] (state, payload) {
    state.areAllCardsSelected = payload.areAllCardsSelected
  },

  /**
   * @description Store flag when user select all cards or not.
   * @param {state} state of store
   * @param {payload} areAllCardsSelected flag indicating all cards are selected or no
   */
  [types.SELECT_ALL_NOTIFICATIONS_ACTIVE] (state, payload) {
    state.areAllNotificationsSelected = payload.areAllNotificationsSelected
  },

  /**
   * @description Set properties for search information.
   * @param {state} state of store
   * @param {payload} searchMade flag indicating a search have been made
   * @param {payload} searchQuery Search query string
   * @param {payload} searchFilters Description of search filters
   */
  [types.SET_SEARCH_INFORMATION] (state, payload) {
    state.saveSearch.searchMade = payload.searchMade
    state.saveSearch.searchQuery = payload.searchQuery
    state.saveSearch.searchFilters = payload.searchFilters
    state.saveSearch.newSearchQuery = payload.newSearchQuery
  },

  /**
   * @description Set properties for search information.
   * @param {state} state of store
   * @param {payload} searchId id of the search
   * @param {payload} searchSaved flag indicating a search have been saved
   */
  [types.SET_SEARCH_SAVED_INFORMATION] (state, payload) {
    state.saveSearch.searchId = payload.searchId
    state.saveSearch.searchSaved = payload.searchSaved
  },

  /**
   * @description Updates store flag that indicates if is saved search mobile.
   * @param {state} state of store.
   */
  [types.CHECK_IS_SAVED_SEARCH_MOBILE] (state) {
    state.phoneResolution = window.innerWidth < 576
    state.saveSearchPagination.maxPages = state.phoneResolution ? 3 : 6
    state.myRequestPagination.maxPages = state.phoneResolution ? 3 : 6
  },

  /**
   * @description Set properties for search being edited information.
   * @param {state} state of store
   * @param {payload} isEditingSearch flag indicating if is a saved search being edited
   * @param {payload} editingSearchInfo information of the saved search being edited
   */
  [types.SET_SEARCH_EDITING_INFORMATION] (state, payload) {
    state.saveSearch.isEditingSearch = payload.isEditingSearch
    state.saveSearch.editingSearchInfo = Object.assign({}, payload.editingSearchInfo)
  },

  /**
   * @description Set properties for search being edited information.
   * @param {state} state of store
   * @param {payload} editingSearchHasChanged flag indicating if saved search being edited has changed
   */
  [types.SET_SEARCH_EDITING_HAS_CHANGED] (state, payload) {
    state.saveSearch.editingSearchHasChanged = payload.editingSearchHasChanged
  },

  /**
   * @description Set number of page and size of saved searches
   * @param {payload} size number of results
   * @param {payload} page number of page
   */
  [types.SET_SAVED_SEARCHES_PAGINATION] (state, payload) {
    state.saveSearchPagination.size = payload.size
    state.saveSearchPagination.page = payload.page
  },

  /**
   * @description Update saved searches url
   */
  [types.UPDATE_SAVED_SEARCHES_URL] (state) {
    router.push({
      name: 'savedSearches',
      query: {
        t: state.saveSearchPagination.size ? state.saveSearchPagination.size : '',
        k: state.saveSearchPagination.page ? state.saveSearchPagination.page : ''
      }
    })
  },

  /**
   * @description Set flag to hide or show footer
   */
  [types.SHOWFOOTER] (state, payload) {
    state.showFooter = payload.showFooter
  },

  /**
   * @description Set flag to hide or show footer
   */
  [types.SET_CATEGORY_SELECTED] (state, payload) {
    state.filters.filterCategories[payload.index].isSelected = payload.isSelected
  },

  /**
   * @description Set the state isGridView
   * @param {payload} isGridView new boolean value to set the state
   */
  [types.SET_GRID_VIEW] (state, payload) {
    state.isGridView = payload.isGridView
  },

  /**
   * @description Save globaly file uploaded.
   * @param {state} state of store
   * @param {payload} file uploaded
   */
  [types.SAVE_UPLOAD_FILE] (state, payload) {
    state.file = payload.file
  },

  /**
   * @description Set number of page and size of collection detail page
   * @param {payload} size number of results
   * @param {payload} page number of page
   */
  [types.SET_COLLECTION_PAGINATION] (state, payload) {
    state.collectionPagination.size = payload.size
    state.collectionPagination.page = payload.page
  },

  /**
   * @description Update detail collection url
   * @param {payload} id id of the collection
   */
  [types.UPDATE_DETAIL_COLLECTION_URL] (state, payload) {
    router.push({
      name: 'detailCollectionPage',
      params: { idCard: payload.id },
      query: {
        t: state.collectionPagination.size,
        k: state.collectionPagination.page
      }
    })
  },

  /**
   * @description Set sharing token variable
   * @param {payload} sharingToken sharing token
   */
  [types.SET_SHARING_TOKEN] (state, payload) {
    state.sharingToken = payload.sharingToken
  },

  /**
   * @description Update detail collection url
   * @param {payload} tab tab selected
   */
  [types.UPDATE_COLLECTIONS_PAGE_URL] (state, payload) {
    router.push({
      name: 'collections',
      query: {
        s: payload.tab
      }
    })
  },

  /**
   * @description Set number of page and size of my requests
   * @param {payload} size number of results
   * @param {payload} page number of page
   */
  [types.SET_MY_REQUESTS_PAGINATION] (state, payload) {
    state.myRequestPagination.size = payload.size
    state.myRequestPagination.page = payload.page
  },

  /**
   * @description Set items of my requests
   * @param {payload} items array of items
   */
  [types.SET_MY_REQUESTS_PAGINATION_ITEMS] (state, payload) {
    state.myRequestPagination.items = payload.items
  },

  /**
   * @description Update my request url
   */
  [types.UPDATE_MY_REQUESTS_URL] (state) {
    router.push({
      name: 'myRequest',
      query: {
        t: state.myRequestPagination.size ? state.myRequestPagination.size : '',
        k: state.myRequestPagination.page ? state.myRequestPagination.page : ''
      }
    })
  },

  /**
   * @description Remove cards ids data
   * @param {state} state of store
   */
  [types.CLEAR_CARDS_IDS] (state) {
    state.cardsSelectedIds = {}
  },

  /**
   * @description Set sales force opportunity item ids
   * @param {state} state of store
   * @param {payload} opportunityInformation information of the opportunity
   */
  [types.SET_SALES_FORCE_ITEMS] (state, payload) {
    state.salesForceInformation.name = payload.opportunityInformation.opportunityName
    state.salesForceInformation.itemIds = payload.opportunityInformation.itemsResults
  },

  /**
   * @description Sets array of toasts
   * @param {state} state of store
   * @param {payload} file The file of toast
   * @param {payload} height The height of toast
   * @param {payload} current Flag to know if is current toast
   */
  [types.SET_TOASTS_ARRAY] (state, payload) {
    const toast = {
      file: payload.file,
      height: payload.height
    }
    state[payload.current ? 'currentToasts' : 'nextToasts'].push(toast)
  },

  /**
   * @description Remove toast from current toasts array
   * @param {state} state of store
   * @param {payload} file The toast to remove
   */
  [types.REMOVE_TOAST] (state, payload) {
    const toastIndex = state.currentToasts.findIndex(toast => toast.file === payload.file)
    state.currentToasts.splice(toastIndex, 1)
  },

  /**
   * @description Remove toasts from next toasts array
   * @param {state} state of store
   * @param {payload} number The number of toasts to remove
   */
  [types.REMOVE_NEXT_TOASTS] (state, payload) {
    state.nextToasts.splice(0, payload.number)
  },

  /**
   * @description Update notifications page url
   * @param {payload} id id of the collection
   */
  [types.UPDATE_NOTIFICATIONS_URL] (state, payload) {
    router.push({
      name: 'notifications',
      query: {
        t: state.notificationsPagination.size,
        k: state.notificationsPagination.page
      }
    })
  },

  /**
   * @description Set number of page and size in notifications page
   * @param {payload} size number of results
   * @param {payload} page number of page
   */
  [types.SET_NOTIFICATIONS_PAGINATION] (state, payload) {
    state.notificationsPagination.size = payload.size || state.notificationsPagination.size
    state.notificationsPagination.page = payload.page || state.notificationsPagination.page
  },

  /**
   * @description Updates store flag that indicates if highlight modal must be on mobile design.
   * @param {state} state of store.
   */
  [types.TOGGLE_MOBILE_HIGHLIGHT_MODAL] (state) {
    state.isHighLightModalMobile = window.innerWidth < 668
  },

  [types.CLEAN_NOTIFICATIONS_IDS_TO_ACTION] (state, payload) {
    state.notificationsIdsToAction = []
    state.counterSelectedNotifications = 0
  },

  /**
   * @description Adds / removes given item from selection
   * @param {state} state of store.
   * @param {payload} itemId item id
   */
  [types.TOGGLE_COLLECTION_ITEM_SELECTION] (state, payload) {
    if (state.collectionItemsIdsSelected.includes(payload.itemId)) {
      state.collectionItemsIdsSelected = state.collectionItemsIdsSelected.filter(e => e !== payload.itemId)
    } else {
      state.collectionItemsIdsSelected.push(payload.itemId)
    }
  },

  /**
   * @description selects / deselects all items from collection
   * @param {state} state of store.
   * @param {payload} areAllItemsSelected boolean to know if items will be selected or deselected.
   */
  [types.TOGGLE_ALL_COLLECTION_ITEMS_SELECTION] (state, payload) {
    state.collectionItemsIdsSelected = payload.areAllItemsSelected ? state.collectionItemsIds : []
  }
}

const actions = {

  /**
   * Searches a filter based on category and description given and add this new filter to list of
   * selected filters.
   * @param context current context of store
   * @param {payload} category category of filter to add.
   * @param {payload} description description of filter to add.
   */
  addNewSelectedFilter (context, payload) {
    const newFilter = context.state.filters.filterByCategory[payload.category].find(filter => filter.description === payload.description)
    if (newFilter) {
      context.state.filters.selectedFilters.push(newFilter)
    }
  },

  /**
   * @description Service to retrieve the suggestions word when user type.
   * @param {payload} filterSuggestion String with rest of url if User is into IN flow
   */
  predictiveSearch: function (context, payload) {
    const filters = payload.filterSuggestion || ''
    if (context.state.isThereSearch.length > 2) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          url: `${process.env.VUE_APP_SUGGESTIONS_SERVICE}Suggestions?${filters}top=6&fuzzy=false&term=${context.state.isThereSearch}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
          }
        }).then(
          (response) => {
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    }
  },

  /**
   * @description Get results from search performed by user
   * @param {payload} urlTerm Url in browser when the user copy and paste in a new tab
   */
  getResultsSearch (context) {
    context.commit({
      type: 'TOGGLE_SHOW_DATA',
      toggle: false
    })
    const fields = storeConstants.fieldsForSearchQuery.join(',')
    const fieldsQuery = `r=${fields}`
    const offset = context.state.pagination.size * (context.state.pagination.page - 1)
    const filters = context.state.filters.queryFilter ? ` and (${context.state.filters.queryFilter})` : ''
    const getIds = (context.state.searchValue.length || context.state.filters.selectedFilters.length || context.state.source.toLowerCase() !== 'all sites') && context.state.refreshCardsIdsNeeded
    let searchValue = context.state.exactSearch ? `"${context.state.searchValue}"` : context.state.searchValue
    searchValue = context.getters.scapeSpecialChars(storeConstants.specialChars.SEARCH_TERM, searchValue)
    const forceSearch = `${!(context.state.searchValue && context.state.exactSearch)}`
    const searchQuery = `s=${context.state.searchSource === '' ? 'all' : `(source eq '${context.state.searchSource}')`}${filters}&q=${searchValue}&${fieldsQuery}&m=all&t=${context.state.pagination.size}&k=${offset}&o=${context.state.sortType.parameter} ${context.state.sortType.order}&u=${process.env.VUE_APP_NODE_ENV}&c=${forceSearch}`
    const newSearchQuery = {
      source: context.state.searchSource,
      page: context.state.pagination.page,
      size: context.state.pagination.size,
      returnFields: storeConstants.fieldsForSearchQuery,
      query: searchValue,
      order: `${context.state.sortType.parameter} ${context.state.sortType.order}`,
      spellCheck: forceSearch,
      filters: context.getters.getQueryFilters
    }
    const searchFilters = context.state.filters.queryFilter ? context.state.filters.selectedFilters.map(selectedFilter => {
      return { category: selectedFilter.category, description: selectedFilter.description }
    }) : ''

    context.dispatch({
      type: 'setSearchInformation',
      searchQuery: searchQuery,
      searchFilters: searchFilters,
      newSearchQuery: newSearchQuery
    })
    context.commit({
      type: 'SET_CARD_IDS_LOADING',
      cardIdsLoading: getIds
    })

    if (router.currentRoute._value.name !== 'home') {
      context.commit({
        type: 'UPDATE_URL'
      })
    }

    if (!context.state.searchValue && !filters && !context.state.searchSource) {
      context.commit({
        type: 'CLEAR_CARDS_IDS'
      })
    }

    return new Promise((resolve, reject) => {
      if (context.state.defaultFlow === 'salesForce') {
        newSearchQuery.filters = [{ field: 'id', values: context.state.salesForceInformation.itemIds }]
      }
      context.dispatch({
        type: 'callSearchApi',
        params: newSearchQuery
      }).then(
        (response) => {
          if (getIds) {
            context.dispatch({
              type: 'getAllCardsId',
              totalItems: response.data['@odata.count']
            })
          }

          response.data.value.forEach((cardResult) => {
            if (cardResult.source === 'sharepoint') cardResult.src_url = `${process.env.VUE_APP_GP_SERVICES}sharepoint/previewurl/${cardResult.id}`
            if (cardResult.source === 'gogrey') {
              switch (cardResult.internal_category) {
                case 'Opportunity':
                  cardResult.source = 'gogreyOpportunity'
                  break
                case 'Article':
                  cardResult.source = 'gogreyNews'
                  break
                case 'Highlight':
                  cardResult.source = 'gogreyHighlight'
                  break
                case 'Spotlight':
                  cardResult.source = 'gogreySpotlight'
                  break
              }
            }
          })

          context.commit({
            type: 'GET_RESULTS_SEARCH',
            dataCards: response.data
          })

          context.commit({
            type: 'SET_REACTIONS',
            cards: response.data.value,
            email: context.state.dataUserLogin.email
          })

          if (!context.state.exactSearch) {
            context.commit({
              type: 'SET_SPELL_SUGGESTION',
              newSpell: response.data.correctedSearchTerm,
              originalResults: response.data.originalHasResults,
              newSpellNumber: response.data.correctedResultNumber
            })
          }

          context.commit({
            type: 'TOGGLE_SHOW_DATA',
            toggle: true
          })
          resolve(response.data)
        },
        (error) => {
          if (error.response && error.response.status) {
            if (error.response.status !== 401 && error.response.status >= 400 && error.response.status < 600) {
              context.commit({ type: 'SHOW_MAINTENANCE_MODE' })
            }
          }
          context.commit({
            type: 'TOGGLE_SHOW_DATA',
            toggle: true
          })
          reject(error)
        }
      )
    })
  },

  /**
   * @description Gets list of filters grouped by category.
   * @param {context} context of store.
   * @param {payload} keepCategory flag to indicate if selected category should be the same after filter refresh.
   */
  getFilters (context, payload) {
    const appFilters = context.state.defaultFlow ? context.state.defaultFlow : 'search'
    let searchTerm = context.state.spellSuggestion.newSpell || context.state.searchValue
    searchTerm = context.state.exactSearch ? context.state.searchValue : searchTerm
    searchTerm = context.getters.scapeSpecialChars(storeConstants.specialChars.SEARCH_TERM, searchTerm.replace(/&/g, '%26'))

    return new Promise((resolve, reject) => {
      context.commit({
        type: 'UPDATE_URL'
      })

      const endpointData = {
        query: searchTerm,
        filters: context.state.filters.queryBetweenFilters
      }

      if (!context.state.defaultFlow) endpointData.source = context.state.searchSource

      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_GP_SERVICES}filters/${appFilters}`,
        data: endpointData
      }).then(
        (response) => {
          context.commit({
            type: 'GET_FILTERS',
            filters: response.data
          })

          context.commit({
            type: 'UPDATE_FILTER_VERSION'
          })

          context.commit({
            type: 'GET_FILTER_CATEGORIES',
            filters: response.data
          })

          context.commit({
            type: 'SELECT_CATEGORY_FILTER',
            keepCategory: payload.keepCategory
          })

          resolve(context.state.filters.filterList)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Makes a new search using filters selected by user.
   * @param {context} context
   * @param {payload} payload
   */
  applyFilters (context, payload) {
    context.commit({
      type: 'SET_REFRESH_CARDS_FLAG',
      refreshCardsIdsNeeded: true
    })

    if (!context.state.filters.selectedFilters) {
      return
    }

    const filtersGrouped = context.state.filters.selectedFilters.reduce((obj, filter) => {
      (obj[filter.category] = obj[filter.category] || []).push(context.getters.scapeSpecialChars(storeConstants.specialChars.FIlTERS, filter.description))
      return obj
    }, {})

    const filterStrings = []
    for (const category in filtersGrouped) {
      const filters = filtersGrouped[category].join(`${storeConstants.separatorSearchApi}`)

      if (category === 'content_type' || category === 'file_type' || category === 'publish_year' ||
          category === 'persona_title' || category === 'year_created' || category === 'asset_type' ||
          category === 'asset_status' || category === 'frame_rate' || category === 'qc_status' ||
          category === 'internal_category' || category === 'studio') {
        filterStrings.push(`search.in(${category}, '${filters}','${storeConstants.separatorSearchApi}')`)
      } else {
        filterStrings.push(`${category}/any(t: search.in(t, '${filters}','${storeConstants.separatorSearchApi}'))`)
      }
    }

    context.commit({
      type: 'UPDATE_QUERY_FILTER',
      queryFilter: filterStrings.join(' and ')
    })

    context.dispatch({
      type: 'getResultsSearch'
    })
  },

  /**
   * @description Launches a request to store info of an intelligence record.
   * @param {context} context of store.
   * @param {payload} id of intelligence document to retrieve similar thumbnails.
   */
  getSimilarThumbnails (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_RELATED_ASSETS_SERVICE}relatedItems?top=3&itemId=${payload.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then((response) => {
        resolve(response.data)
      },
      (error) => {
        reject(error)
      })
    })
  },

  /**
   * @description Send request to know if request to access or open in sharepoint
   * @param {payload} indexId ID document
   */
  fileAccess (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_INTEL_API}File/Access/${payload.indexId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  },

  /**
   * @description Send request to ask access to see document
   * @param {payload} indexId ID document
   */
  requestAccess (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_INTEL_API}File/Access/${payload.indexId}/Request`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  },

  /**
   * @description Call the service to like an item
   * @param {context} context
   * @param {payload} id contain the item id
   */
  likeToggle (context, payload) {
    const apiMethod = payload.isLiked ? 'Unlike' : 'Like'
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_GP_SERVICES}SocialMedia/${apiMethod}/${payload.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then((response) => {
        context.commit({
          type: 'UPDATE_LIKES',
          isLike: !payload.isLiked,
          id: payload.id
        })

        resolve(response)
      })
    })
  },

  /**
   * @description Get detail one card
   * @param {payload} id Card id
   * @param {payload} fields Fields needed
   */
  getDetailbyId (context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch({
        type: 'callSearchApi',
        params: {
          source: 'intelligence',
          returnFields: payload.fields,
          filters: [
            {
              field: 'id',
              values: [payload.id]
            }
          ]
        }
      }).then(
        (response) => {
          if (response.data.value.length) {
            context.commit({
              type: 'SET_REACTIONS',
              cards: response.data.value,
              email: context.state.dataUserLogin.email
            })
            resolve(response.data.value[0])
          } else {
            router.push({ name: 'pageNotFound' })
            console.error('Item not found')
          }
        },
        (error) => {
          router.push({ name: 'pageNotFound' })
          console.error(error)
          reject(error)
        }
      )
    })
  },

  /**
   * @description Get flag that indicate if the current user can delete the item
   * @param {payload} id Item id
   */
  canDeleteIntelligenceItem (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_INTEL_API}Content/CanEdit/${payload.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Delete the selected item
   * @param {payload} id Item id to delete
   */
  deleteIntellItem (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_INTEL_API}Content/${payload.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Searches a term to retrieve a list of users that match with the term, including external users.
   * @param {context} context of the store.
   * @param {payload} searchTerm term for searching a user.
   */
  searchExternalUser (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_SUGGESTIONS_SERVICE}suggestions/names?term=${payload.searchTerm}`
      }).then(response => resolve(response.data),
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  },

  /**
   * @description Makes a call to API to retrieve the agency and office of a given user.
   * @param {context} context of the store.
   * @param {payload} email of the given user to search its agency and office.
   */
  getUserInfo (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_INTEL_API}Author/${payload.email}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  },

  /**
   * @description Makes a call to API to update views counter for an article.
   * @param {context} context of the store.
   * @param {payload} id of the article to update its views counter.
   */
  updateViews (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_GP_SERVICES}SocialMedia/View/${payload.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          if (payload.setLikes) {
            context.commit({
              type: 'SET_REACTIONS_DETAIL',
              item: { id: payload.id, source: 'work', likes: response.data.likes, liked_by: response.data.likesBy, views: response.data.views },
              email: context.state.dataUserLogin.email
            })
          } else {
            context.commit({
              type: 'UPDATE_VIEWS',
              id: payload.id,
              count: response.data.views
            })
          }
          resolve(response.data)
        },
        (error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * @description Makes a call to API to know if a title already exists.
   * @param {context} context of the store.
   * @param {payload} title title of the article to know it already exists.
   */
  validateIntelTitle (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_INTEL_API}Content/istitleavailable`,
        data: {
          title: payload.title
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * @description Get collections mine
   * @param {context} context of the store.
   * @param {payload} id Card id to be added to the collection.
   */
  getCollections (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/list/mix`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          context.commit({
            type: 'GET_COLLECTIONS_MINE',
            collectionsMine: response.data,
            cardsIdToAdd: payload.ids
          })
          resolve(context.state.collectionsMine)
        },
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  },

  getAllCardsId (context, payload) {
    const pageSize = 1000
    const totalIterations = Math.ceil(payload.totalItems / pageSize)
    let currentIteration = 1

    if (!totalIterations) {
      context.commit({
        type: 'SET_CARD_IDS_LOADING',
        cardIdsLoading: false
      })
      return
    }

    const paramsObject = context.state.saveSearch.newSearchQuery
    paramsObject.order = 'date_created desc'

    const interval = setInterval(() => {
      const iteration = currentIteration
      context.dispatch({
        type: 'callSearchApi',
        params: {
          ...paramsObject,
          returnFields: ['id'],
          size: pageSize,
          page: iteration
        }
      }).then((response) => {
        if (response.data.value) {
          response.data.value.forEach((item) => {
            context.state.cardsSelectedIds[item.id] = false
          })
        }

        if (iteration === totalIterations) {
          context.commit({
            type: 'SET_CARD_IDS_LOADING',
            cardIdsLoading: false
          })

          context.commit({
            type: 'SET_REFRESH_CARDS_FLAG',
            refreshCardsIdsNeeded: false
          })
        }
      }).catch((error) => {
        console.error(error)
      })

      if (iteration === totalIterations) {
        clearInterval(interval)
      }
      currentIteration++
    }, 300)
  },

  /**
   *
   * @description Get pending tours per App
   * @param {context} context of store
   * @param {payload} site Site where it is going to do the call to BE to know if it has pending tours to do
   */
  getPendingTours: function (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}Walkthrough/${payload.site}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   *
   * @description Complete tour
   * @param {context} context of store
   * @param {payload} tourId Tour ID to say BE the user already completed the tour
   */
  completeTour: function (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_GP_SERVICES}Walkthrough/Complete/${payload.tourId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Makes an API call to add an item to the user's portfolio.
   * @param {context} context of store
   * @param {payload} id string with the id of the item to add to the user's portfolio
   */
  addItemToPortfolio (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}portfolio/add`,
        data: {
          userId: context.state.dataUserLogin.ecCode,
          itemId: payload.id
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Gets a flag to know if an item is on my portfolio.
   * @param {context} context of the store.
   * @param {payload} id of the item to check if it exists on my portfolio.
   */
  isItemOnMyPortfolio (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}portfolio/is_added/${payload.id}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then((response) => resolve(response.data), (error) => reject(error))
    })
  },

  /**
   * @description Deletes an item from my portfolio.
   * @param {context} context of the store.
   * @param {payload} id of the item to remove from portfolio.
   */
  deleteItemFromPortfolio (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}portfolio/remove/${payload.id}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then((response) => resolve(response), (error) => reject(error))
    })
  },

  /**
   * @description Get manager data
   * @param {*} context of store
   * @param {payload} ecCode of manager
   * @param {payload} namePositionNumber Position in array
   */
  getManagerData (context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch({
        type: 'callSearchApi',
        params: {
          query: payload.ecCode,
          searchFields: ['id'],
          source: 'persona',
          returnFields: ['id', 'midsize_picture', 'title', 'persona_title', 'email', 'agency', 'business_region']
        }
      }).then(
        (response) => {
          if (response.data.value[0]) {
            context.commit({
              type: 'GET_MANAGER_INFO',
              managerInfo: response.data.value[0],
              namePositionNumber: payload.namePositionNumber
            })
            resolve(response.data.value[0])
          } else {
            resolve(false)
          }
        },
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  },

  /**
   * @description Get the data of an intel item to be edited.
   * @param {context} context of store
   * @param {payload} id of the item to get.
   */
  getIntelDetail (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_INTEL_API}Content/${payload.id}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then(response => resolve(response), error => reject(error))
    })
  },

  /**
   * @description Set information about the search
   * @param {context} context of store
   */
  setSearchInformation (context, payload) {
    const searchMade = context.state.searchValue || context.state.searchSource !== '' || context.state.filters.selectedFilters.length
    context.commit({
      type: 'SET_SEARCH_INFORMATION',
      searchMade: searchMade,
      searchQuery: payload.searchQuery,
      searchFilters: payload.searchFilters,
      newSearchQuery: payload.newSearchQuery
    })
    if (context.state.saveSearch.isEditingSearch) {
      const searchHasChanged = context.state.source.toLowerCase() !== context.state.saveSearch.editingSearchInfo.source ||
                            context.state.searchValue !== context.state.saveSearch.editingSearchInfo.searchTerms ||
                            `${context.state.sortType.parameter} ${context.state.sortType.order}` !== context.state.saveSearch.editingSearchInfo.orderBy ||
                            helpers.arrayDifferents(context.state.saveSearch.searchFilters, context.state.saveSearch.editingSearchInfo.searchFilters)
      context.commit({
        type: 'SET_SEARCH_EDITING_HAS_CHANGED',
        editingSearchHasChanged: searchHasChanged
      })
    } else {
      if (searchMade) {
        axios({
          method: 'POST',
          url: `${process.env.VUE_APP_SAVE_SEARCH_SERVICE}FindSavedSearch`,
          headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
          data: {
            source: context.state.source.toLowerCase(),
            searchTerms: context.state.searchValue,
            searchFilters: context.state.saveSearch.searchFilters,
            orderBy: `${context.state.sortType.parameter} ${context.state.sortType.order}`
          }
        }).then(
          (res) => {
            context.commit({
              type: 'SET_SEARCH_SAVED_INFORMATION',
              searchSaved: res.data.searchSaved,
              searchId: res.data.searchSaved ? res.data.id : ''
            })
          }, (error) => {
            context.commit({
              type: 'SET_SEARCH_SAVED_INFORMATION',
              searchSaved: false,
              searchId: ''
            })
            console.error(error)
          }
        )
      } else {
        context.commit({
          type: 'SET_SEARCH_SAVED_INFORMATION',
          searchSaved: false,
          searchId: ''
        })
      }
    }
  },

  /**
   * @description Save current search.
   * @param {context} context of the store.
   * @param {payload} title search title.
   */
  saveSearch (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_SAVE_SEARCH_SERVICE}searches`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
        data: {
          name: payload.title,
          source: context.state.source.toLowerCase(),
          searchQuery: context.state.saveSearch.searchQuery,
          searchTerms: context.state.searchValue,
          searchFilters: context.state.saveSearch.searchFilters,
          orderBy: `${context.state.sortType.parameter} ${context.state.sortType.order}`,
          numberOfResults: context.state.dataCards['@odata.count'],
          redirectUrl: window.location.href
        }
      }).then((response) => resolve(response.data), (error) => reject(error))
    })
  },

  /**
   * @description Save edited saved search.
   * @param {context} context of the store.
   */
  saveEditedSavedSearch (context) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'PATCH',
        url: `${process.env.VUE_APP_SAVE_SEARCH_SERVICE}searches`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
        data: {
          id: context.state.saveSearch.searchId,
          name: context.state.saveSearch.editingSearchInfo.name,
          source: context.state.source.toLowerCase(),
          searchQuery: context.state.saveSearch.searchQuery,
          searchTerms: context.state.searchValue,
          searchFilters: context.state.saveSearch.searchFilters,
          orderBy: `${context.state.sortType.parameter} ${context.state.sortType.order}`,
          redirectUrl: window.location.href
        }
      }).then((response) => resolve(response.data), (error) => reject(error))
    })
  },

  /**
   * @description Delete current saved search.
   * @param {context} context of the store.
   * @param {payload} searchId id of the search
   */
  deleteSavedSearch (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_SAVE_SEARCH_SERVICE}searches/${payload.searchId}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then((response) => resolve(response.data), (error) => reject(error))
    })
  },

  /**
   * @description Get list of saved searches.
   * @param {context} context of store.
   */
  getSavedSearches (context) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_SAVE_SEARCH_SERVICE}searches?page=${context.state.saveSearchPagination.page}&size=${context.state.saveSearchPagination.size}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then(response => resolve(response), error => reject(error))
    })
  },

  /**
   * @description Get list of notifications.
   * @param {context} context of store.
   * @param {payload} page number
   * @param {payload} size count per page
   */
  getNotifications (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}notifications/all?page=${payload.page}&size=${payload.size}&source=${context.state.sourceNotifications}&status=${context.state.statusNotifications}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
          'Cache-Control': 'no-cache, max-age=0, no-store'
        }
      }).then(response => resolve(response), error => reject(error))
    })
  },

  /**
   * @description Request to save or update a comment.
   * @param {payload} itemId id of the card.
   * @param {payload} commentId id of the comment.
   * @param {payload} content content of the comment.
   */
  saveComment (context, payload) {
    let method = 'POST'
    const data = {
      itemId: payload.itemId,
      content: payload.content
    }
    if (payload.commentId) {
      method = 'PATCH'
      data.commentId = payload.commentId
    }
    return new Promise((resolve, reject) => {
      axios({
        method: method,
        url: `${process.env.VUE_APP_GP_SERVICES}SocialMedia/Comments`,
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        })
    })
  },

  /**
   * @description Request to show the comment detail every card
   * @param {context} context of store
   * @param {payload} idCard It's id card to query
   */
  getDetailComments (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}SocialMedia/Comments/${payload.idCard}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          if (response.data.success) {
            response.data.comments.map(comment => {
              comment.content = context.getters.removeWhitespaces(comment.content)
              return comment
            })
            resolve(response)
          }
        },
        (error) => {
          reject(error)
        })
    })
  },

  /**
   * @description Mark notification as read.
   * @param {payload} notificationId id of the notification to be marked as read.
   */
  markNotificationsAsRead (context, payload) {
    return axios({
      method: 'PATCH',
      url: `${process.env.VUE_APP_GP_SERVICES}notifications/read`,
      data: {
        allExcept: false,
        ids: [payload.notificationId]
      },
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
    })
  },

  /**
   * @description Mark all notifications as read
   */
  markSelectedNotificationsAsRead (context) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'PATCH',
        url: `${process.env.VUE_APP_GP_SERVICES}notifications/read`,
        data: {
          allExcept: context.state.areAllNotificationsSelected,
          ids: context.state.notificationsIdsToAction,
          source: context.state.sourceNotifications,
          status: context.state.statusNotifications
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then(
        (response) => {
          context.state.selectAllNotificationsChecked = false
          resolve(response)
        },
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  },

  /**
   * @description Mark all notifications as read
   */
  getAnyNotificationUnread () {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_GP_SERVICES}notifications/pending`,
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
    })
  },

  /**
   * @description Delete list of notifications
   * @param {context} context of store.
   */
  deleteNotifications (context) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_GP_SERVICES}notifications`,
        data: {
          allExcept: context.state.areAllNotificationsSelected,
          ids: context.state.notificationsIdsToAction,
          source: context.state.sourceNotifications,
          status: context.state.statusNotifications
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
          'Cache-Control': 'no-cache, max-age=0, no-store'
        }
      }).then(
        (response) => {
          context.state.selectAllNotificationsChecked = false
          resolve(response)
        },
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  },

  /**
   * @description Allow to use search API
   * @param {payload} params Object parameters to search
   */
  callSearchApi (contex, payload) {
    const dataQuery = {
      source: '',
      searchMode: 'any'
    }
    for (const prop in payload.params) { dataQuery[prop] = payload.params[prop] }
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: process.env.VUE_APP_NEW_SEARCH_API,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
        data: dataQuery
      }).then(response => resolve(response), error => reject(error))
    })
  },

  /**
   * @description Get details of a collection by id
   * @param {context} context of store
   * @param {payload} id of the collection
   * @param {payload} page of the collection
   * @param {payload} size number of items per page
   * @param {payload} sharingToken token to share the collection
   */
  getCollectionDetails (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}collections/${payload.id}/details/${context.state.collectionPagination.page}/${context.state.collectionPagination.size}/${context.state.sharingToken}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
          'Cache-Control': 'no-cache, max-age=0, no-store'
        }
      }).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          if (error.response && error.response.status && error.response.status === 404) {
            router.push({ name: 'collectionNotFound' })
          }
          reject(error)
        }
      )
    })
  },

  /**
   * @description Request to upload custom collection thumbnail
   * @param {context} context of store
   * @param {payload} collectionId collection to change thumbnail
   */
  uploadCollectionThumbnail (context, payload) {
    const data = new FormData()
    data.append('collectionId', payload.collectionId)
    data.append('thumbnail', context.state.file)
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_GP_SERVICES}collections/thumbnail/custom`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        },
        data
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Get current user requests data
   * @param {context} context of store
   */
  getMyRequestsData (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}myrequest`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          context.commit({
            type: 'SET_MY_REQUESTS_PAGINATION_ITEMS',
            items: response.data
          })
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Get details of a request by id
   * @param {context} context of store
   * @param {payload} requestId id of the request
   * @param {payload} requestType type of the request
   */
  getRequestDetails (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_GP_SERVICES}myrequest/details`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        },
        data: {
          id: payload.requestId,
          type: payload.requestType
        }
      }).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  /**
   * @description Set vh mobile devices style
   */
  setVhMobileDevices () {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vhMobileDevice', `${vh}px`)
  },

  /**
   * @description Get information of a sales force opportunity
   * @param {context} context of store
   * @param {payload} idOpportunity id of the opportunity
   */
  getSalesForceInformation (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_SALES_FORCE_SERVICE}search/salesforce/${payload.idOpportunity}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          context.commit({
            type: 'SET_SALES_FORCE_ITEMS',
            opportunityInformation: response.data
          })
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description deletes selected items from collection
   * @param {context} context of store
   * @param {payload} collectionId id of the collection
   */
  deleteSelectedItems (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'PATCH',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/${payload.collectionId}/Remove`,
        data: {
          items: context.state.collectionItemsIdsSelected
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then(
        (response) => {
          context.state.collectionItemsIdsSelected = []
          resolve(response)
        },
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  }
}

export default createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {
    work: workModule,
    form: formModule,
    collections: collectionsModule
  }
})
