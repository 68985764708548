<template>
  <section class="containerTourPrincipal">
    <section class="containerTourPrincipal__overlay" v-if="isOverlayVisible"></section>
    <genericTour v-if="store.state.activeTours.GSV_feedbackButton.isPending"
                 name="GSV_feedbackButton"
                 :isOverlayVisible= "false"
                 :steps="configuration['GSV_feedbackButton'].steps"
                 :options="configuration['GSV_feedbackButton'].myOptions"
                 :isReady="store.state.activeTours.GSV_feedbackButton.isRenderReady"/>
    <genericTour v-if="store.state.activeTours.GSV_threeDotsCardMenu.isPending"
                 class="containerTourPrincipal__threeDotsCardMenu"
                 name="GSV_threeDotsCardMenu"
                 :steps="configuration['GSV_threeDotsCardMenu'].steps"
                 :options="configuration['GSV_threeDotsCardMenu'].myOptions"
                 :isReady="store.state.activeTours.GSV_threeDotsCardMenu.isRenderReady"/>
  </section>
</template>

<script>
import { onMounted, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import genericTour from '../genericTour/genericTour'
import configurationTours from '../configurationTours'

export default {
  name: 'principal',
  components: {
    genericTour
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const isOverlayVisible = ref(true)
    const configuration = reactive(configurationTours.tours)

    onMounted(() => {
      const isARouteWithTours = configurationTours.routesWithTours.some(route => route === route.name)
      if (isARouteWithTours) {
        getPendingTours()
      } else {
        isOverlayVisible.value = false
      }
    })

    /**
     * @description Gets the list of all pending tours.
     */
    function getPendingTours () {
      document.addEventListener('readystatechange', event => {
        if (event.target.readyState === 'complete') {
          store.dispatch({
            type: 'getPendingTours',
            site: 'search'
          }).then((response) => {
            const pendingTours = response.data.pendingWalkthroughs
            pendingTours.forEach(tour => {
              store.commit({
                type: 'TOGGLE_ACTIVE_TOUR',
                tourName: tour,
                property: 'isPending',
                value: true
              })
            })
            removeOverlay(pendingTours.length ? 350 : 0)
          }).catch(() => {
            removeOverlay()
          })
        }
      })
    }

    /**
     * @description Removes the overlay after a given delay.
     * @param {timer} number of miliseconds while the overlay will be still visible.
     */
    function removeOverlay (timer = 0) {
      setTimeout(() => {
        isOverlayVisible.value = false
      }, timer)
    }

    return {
      store,
      route,
      isOverlayVisible,
      configuration
    }
  }
}
</script>
