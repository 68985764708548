import axios from 'axios'
import {
  generatePublicKey,
  generateAuthKey,
  urlBase64ToUint8Array
} from './utils'
import store from '../store/index'

/**
 * @description Subscribe to the user for push notifications with the subscription data
 * and set in localstorage when this is successful
 * @param subscription object with subscription data
 * @param token logged user token
 */
const setSubscription = (subscription, token) => {
  const publicKey = generatePublicKey(subscription)
  const auth = generateAuthKey(subscription)
  axios({
    method: 'POST',
    url: `${process.env.VUE_APP_PUSH_SERVICE}v2/subscribe/news/${process.env.VUE_APP_PUSH_CHANNEL}?employeecode=${store.state.dataUserLogin.ecCode}`,
    data: {
      notificationEndPoint: subscription.endpoint,
      publicKey,
      auth
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => {
      if (response.status === 200) {
        console.log('Subscription completed..')
      }
    })
    .catch(error => console.error('Error: ', error))
}

/**
 * @description Validate if the user is already register
 * @param registration is the object from browser
 * @param subscription object with subscription data
 * @param token is the user access token
 */

const hasPreviousSubscription = (registration, subscription, token) => {
  const publicKey = generatePublicKey(subscription)
  const auth = generateAuthKey(subscription)
  axios({
    method: 'POST',
    url: `${process.env.VUE_APP_PUSH_SERVICE}v2/subscriptions/news/${process.env.VUE_APP_PUSH_CHANNEL}?employeecode=${store.state.dataUserLogin.ecCode}`,
    data: {
      notificationEndPoint: subscription.endpoint,
      publicKey,
      auth
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => {
      if (parseInt(response.data) === 0) {
        registration.update()
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            getSubscriptionDataAndProcess(registration, token, 'subscribe')
          }
        })
      }
    })
    .catch(error => console.error('Error: ', error))
}

/**
 * @description generate vapid key and get data from browser to validate previous subscriptions or subscribe
 * @param registration is the object from browser
 * @param token is the user access token
 * @param action indicate what to do after to get subscription information
 */
const getSubscriptionDataAndProcess = (registration, token, action) => {
  axios({
    method: 'GET',
    url: `${process.env.VUE_APP_PUSH_SERVICE}v2/key/news/${process.env.VUE_APP_PUSH_CHANNEL}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => {
      if (response.data) {
        registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(response.data)
        })
          .then(subscription => {
            if (action === 'subscribe') {
              setSubscription(subscription, token)
            } else {
              hasPreviousSubscription(registration, subscription, token)
            }
          })
          .catch(error => console.error('Error: ', error))
      }
    })
    .catch(error => console.error('Error: ', error))
}

/**
 * @description initialize service worker and pushservice when the application has a token
 * @param token logged user token
 */
export const init = (token) => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker.register('../../../static/sw.js')
      .then(registration => {
        getSubscriptionDataAndProcess(registration, token, 'validate')
      })
      .catch(error => console.error('Error', error))
  }
}
