<template>
  <section class="containerGenericTour">
    <section class="containerGenericTour__overlay" v-if="isOverlayVisible && isReady" @click="skipTour"></section>
    <v-tour :name="name" :steps="steps" :options="options" :callbacks="myCallbacks"/>
  </section>
</template>

<script>
import { onMounted, reactive, watchEffect } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'genericTour',
  props: {
    name: {
      type: String,
      required: true
    },
    steps: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      default: null
    },
    isOverlayVisible: {
      type: Boolean,
      default: true
    },
    isReady: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const store = useStore()
    const myCallbacks = reactive({
      onFinish: onStopTour
    })

    onMounted(() => {
      startTour(props.isReady)
    })

    /**
     * @description Function to be executed on every change of isReady property.
     * @param {isComponentReady} boolean with the last value of isReady property.
     */
    watchEffect(() => props.isReady, (isComponentReady) => {
      startTour(isComponentReady)
    })

    /**
     * @description Callback when the user press GOT IT button.
     */
    function onStopTour () {
      hideTour()
      store.dispatch({
        type: 'completeTour',
        tourId: props.name
      })
    }

    /**
     * @description Callback when the user clicks on the overlay.
     */
    function skipTour () {
      this.$tours[this.name].skip()
      hideTour()
    }

    /**
     * @description Hides the tour only to UI level.
     */
    function hideTour () {
      store.commit({
        type: 'TOGGLE_ACTIVE_TOUR',
        tourName: props.name,
        property: 'isPending',
        value: false
      })
    }

    /**
     * @description Starts the tour if the flag send it is true.
     * @param {isReady} boolean to know is toru can be started.
     */
    function startTour (isReady) {
      if (isReady) {
        this.$tours[this.name].start()
      }
    }

    return {
      store,
      myCallbacks,
      skipTour
    }
  }
}
</script>
