<template>
  <footer class="containerFooter bookFont">
    <p class="containerFooter__Text">©{{ store.state.currentYear }} Grey Group. A WPP Company.</p>
  </footer>
</template>

<script>
import { useStore } from 'vuex'

export default {
  name: 'Footer',
  setup (props) {
    const store = useStore()

    return {
      store
    }
  }
}
</script>
