<template>
  <div id="app" :class="[!isToolbarHorizontal ? 'leftSpaceDesktop' : 'topSpaceMobile']" v-cloak>
    <tours></tours>
    <interceptors :axios="axiosInstance" />
    <toolbar
      app="search"
      :user="store.state.dataUserLogin.name"
      :ecCode="store.state.dataUserLogin.ecCode"
      :email="store.state.dataUserLogin.email"
      :role="store.state.dataUserLogin.role"
      v-if="store.state.dataUserLogin.isAuthenticated"
      @toolbarLoaded="toolbarLoaded()"
    >
    </toolbar>
    <maintenance v-if="store.state.maintenanceMode" linkTitle="GO.GREY"/>
    <router-view v-else/>
    <Footer v-if="store.state.toolbarLoaded && store.state.showFooter"></Footer>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { initAzureAnalytics } from '@/utils/azureAnalytics'
import { init } from './registerWorker'
import tours from '@/components/tours/principal/principal'
import Footer from '@/components/footer/Footer'
import { onMounted, ref } from 'vue'

export default {
  name: 'App',
  components: {
    tours,
    Footer
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const isToolbarHorizontal = ref(null)
    const axiosInstance = axios

    onMounted(() => {
      setUp()
      resizeSetUp()
      handleResize()
      initAzureAnalytics(process.env.VUE_APP_AZURE_TRACKING_KEY)
    })

    /**
     * @description Handler to know the size of the screen
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
      })
    }

    /**
    * @description Handler to know the size of the screen
    */
    function resizeSetUp () {
      store.commit({
        type: 'CHECK_IS_MOBILE'
      })

      store.commit({
        type: 'CHECK_IS_SAVED_SEARCH_MOBILE'
      })

      store.commit({
        type: 'TOGGLE_MOBILE_RESOLUTION'
      })

      store.commit({
        type: 'TOGGLE_TABLET_RESOLUTION'
      })

      store.commit({
        type: 'TOGGLE_MOBILE_HIGHLIGHT_MODAL'
      })

      store.dispatch('setVhMobileDevices')

      if (store.state.isMobile || window.innerWidth < 1024) {
        isToolbarHorizontal.value = true
      } else {
        isToolbarHorizontal.value = false
      }
    }

    /**
     * @description Run app when it inits
     */
    function setUp () {
      store.commit({
        type: 'DATA_USER_LOGIN'
      })

      init(localStorage.getItem('sessionToken'))
    }

    /**
     * @description Sets that toolbar has loaded
     */
    function toolbarLoaded () {
      store.commit({
        type: 'TOOLBAR_LOADED',
        loaded: true
      })
    }

    return {
      store,
      route,
      isToolbarHorizontal,
      axiosInstance,
      toolbarLoaded
    }
  }
}
</script>

<style lang='scss' src='./sass/_main.scss'></style>
