import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/**
 * Dependences
 */
import gtag from 'vue-gtag-next'
import externalComponentsFromGrey from 'external-components-from-grey'
import appearDirective from './directives/appear'
import VueClickAway from 'vue3-click-away'
import VueClipboard from 'vue3-clipboard'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueClickAway)
app.use(externalComponentsFromGrey)
if (process.env.VUE_APP_ENABLE_GOOGLE_ANALYTICS === 'true') {
  app.use(gtag, {
    property: {
      id: 'G-WDV3HNF8XF'
    }
  })
}
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true
})

/**
 * Directives
 */
app.directive('appear', appearDirective)

app.config.devtools = true
app.mount('#app')
