import axios from 'axios'
import { BlockBlobClient, newPipeline, AnonymousCredential } from '@azure/storage-blob'
import * as types from './mutationTypes'

const state = {
  metadata: {},
  isDataReady: false,
  defaultValues: {},
  formState: {},
  uploadList: [],
  workMetadata: {},
  awardIndex: -1,
  thumbnailInfo: {},
  transactionId: '',
  supportingExtraImageId: '',
  supportingFilesInProcess: 0,
  selectThumbnailInfo: {
    fileProcessed: false,
    fileInformation: {}
  },
  thumbnailSelected: {
    selectionType: 'custom',
    selections: ['', '', '']
  }
}

const mutations = {
  /**
   * @description Sets the object metadata to be used in the form.
   * @param {state} state of store.
   * @param {payload} metadata metadata to be added.
   */
  [types.SET_METADATA] (state, payload) {
    state.metadata = payload.metadata
    state.metadata.years = state.metadata.years.map(year => {
      return { id: year, value: year }
    })
  },

  /**
   * @description Sets the values for industries on the metadata object.
   * @param {state} state of store.
   * @param {payload} metadata status of uploading process.
   */
  [types.SET_METADATA_INDUSTRIES] (state, payload) {
    state.metadata.industries = payload.industries.map((industry) => {
      const industryObj = helpers.getTreeObj(industry.value)
      industryObj.children = industry.children.map((category) => helpers.getTreeObj(category.value))
      return industryObj
    })
  },

  /**
   * @description Sets the values selected previously by the user for industries on the metadata object.
   * @param {state} state of store.
   * @param {payload} industries industries already selected by the user.
   */
  [types.SET_INITIAL_INDUSTRIES] (state, payload) {
    const industriesSelected = payload.industries.reduce((industriesSelected, industry) => {
      const [categorySelected, industrySelected] = industry.split(' : ')
      industriesSelected[categorySelected] = industriesSelected[categorySelected] || {}
      industriesSelected[categorySelected][industrySelected] = industrySelected
      return industriesSelected
    }, {})

    state.metadata.industries = state.metadata.industries.map(category => {
      let count = 0
      if (industriesSelected[category.label]) {
        category.children = category.children.map(industry => {
          if (industriesSelected[category.label][industry.label]) {
            count++
            return helpers.getTreeObj(industry.label, true)
          } else {
            return industry
          }
        })
      }
      category.isIndeterminate = Boolean(count > 0 && count !== category.children.length)
      category.isSelected = count > 0
      return category
    })
  },

  /**
   * @description Sets the default values on the form.
   * @param {state} state of store.
   * @param {payload} defaultValues values to set as default.
   */
  [types.SET_DEFAULT_VALUES] (state, payload) {
    state.defaultValues = payload.defaultValues
    state.defaultValues.author = {
      ...state.defaultValues.author,
      value: `${state.defaultValues.author.name} (${state.defaultValues.author.email})`
    }
  },

  /**
   * @description Sets the flag to know if data is ready and loaded.
   * @param {state} state of store.
   * @param {payload} isDataReady flag to know if data is ready.
   */
  [types.SET_DATA_READY] (state, payload) {
    state.isDataReady = payload.isDataReady
  },

  /**
   * @description Adds a new file object to the list of uploading files.
   * @param {state} state of store.
   * @param {payload} file file to be added.
   * @param {payload} source source of the file.
   * @param {payload} tempId temporary id to identify the file.
   */
  [types.ADD_FILE_TO_UPLOAD_LIST] (state, payload) {
    state.uploadList.push({
      name: payload.file.name,
      size: helpers.formatSizeFile(payload.file.size),
      source: payload.source,
      status: payload.status,
      tempId: payload.tempId,
      progress: payload.progress,
      multiple: payload.multiple,
      fileFromEdition: payload.fileFromEdition || false,
      supportingFileId: payload.supportingFileId || '',
      workItemId: payload.workItemId || ''
    })
  },

  /**
   * @description Update the info of the file in the list.
   * @param {state} state of store.
   * @param {payload} id id of the file.
   * @param {payload} tempId temporary id to identify the file.
   * @param {payload} info complementary info to be added to the file.
   */
  [types.UPDATE_FILE_INFO] (state, payload) {
    const id = payload.tempId || payload.id
    const fileIndex = state.uploadList.findIndex(file => file[payload.tempId ? 'tempId' : 'id'] === id)
    state.uploadList[fileIndex] = Object.assign({}, state.uploadList[fileIndex], payload.info)
  },

  /**
   * @description Delete a file from the list of uploading files.
   * @param {state} state of store.
   * @param {payload} id id of the file.
   */
  [types.DELETE_FILE] (state, payload) {
    const index = state.uploadList.findIndex(file => file.tempId === payload.id)
    state.uploadList.splice(index, 1)
    state.uploadList.map(fileUpload => {
      if (fileUpload.multiple && fileUpload.progress === 100) fileUpload.progress++
    })
  },

  /**
   * @description Sets the object metadata for the work section in the form.
   * @param {state} state of store.
   * @param {payload} metadata metadata to be added.
   */
  [types.SET_WORK_METADATA] (state, payload) {
    state.workMetadata = payload.metadata
  },

  /**
   * @description Sets index of award.
   * @param {state} state of store.
   * @param {payload} awardIndex index of the award.
   */
  [types.SET_AWARD_INDEX] (state, payload) {
    state.awardIndex = payload.awardIndex
  },

  /**
   * @description Set data of thumbnail being uploaded.
   * @param {state} state of store.
   * @param {payload} file file uploaded.
   */
  [types.SET_THUMBNAIL_INFO] (state, payload) {
    state.thumbnailInfo = {
      name: payload.name || '',
      size: payload.size ? helpers.formatSizeFile(payload.size) : 0,
      status: payload.status,
      progress: 0,
      extension: payload.extension || '',
      edition: payload.edition || false
    }
  },

  /**
   * @description Update data of thumbnail being uploaded.
   * @param {state} state of store.
   * @param {payload} thumbnailInfo object with modified properties.
   */
  [types.UPDATE_THUMBNAIL_INFO] (state, payload) {
    state.thumbnailInfo = Object.assign(state.thumbnailInfo, payload.thumbnailInfo)
  },

  /**
   * @description Clear upload file list.
   * @param {state} state of store.
   */
  [types.CLEAR_UPLOAD_FILES] (state) {
    state.uploadList = []
  },

  /**
   * @description Set transaction id global variable.
   * @param {state} state of store.
   * @param {payload} transactionId id of the transaction.
   */
  [types.SET_TRANSACTION_ID] (state, payload) {
    state.transactionId = payload.transactionId
  },

  /**
   * @description Set supporting extra image id global variable.
   * @param {state} state of store.
   * @param {payload} supportingExtraImageId id of the extra image.
   */
  [types.SET_SUPPORTING_EXTRA_IMAGE_ID] (state, payload) {
    state.supportingExtraImageId = payload.supportingExtraImageId
  },

  /**
   * @description Set supporting files in process global variable.
   * @param {state} state of store.
   * @param {payload} supportingFilesInProcess id of the extra image.
   */
  [types.SET_SUPPORTING_FILES_IN_PROCESS] (state, payload) {
    state.supportingFilesInProcess = payload.add ? state.supportingFilesInProcess + 1 : state.supportingFilesInProcess - 1
  },

  /**
   * @description Set object information of select thumbnail flow.
   * @param {state} state of store.
   * @param {payload} fileProcessed flag indicating if video file has been processed.
   * @param {payload} fileInformation object containing sassurl and thumbnails.
   */
  [types.SET_SELECT_THUMBNAIL_INFO] (state, payload) {
    state.selectThumbnailInfo.fileProcessed = payload.fileProcessed
    state.selectThumbnailInfo.fileInformation = payload.fileInformation
  },

  /**
   * @description Set thumbnail selected information.
   * @param {state} state of store.
   * @param {payload} selectedImage image of the selection.
   */
  [types.SET_THUMBNAIL_SELECTED] (state, payload) {
    if (payload.selectionType) state.thumbnailSelected.selectionType = payload.selectionType
    const indexSelection = state.thumbnailSelected.selectionType === 'custom' ? 0 : (state.thumbnailSelected.selectionType === 'autoGenerated' ? 1 : 2)
    state.thumbnailSelected.selections[indexSelection] = payload.selectedImage
  }
}

const actions = {
  /**
   * @description Get common metadata to populate the form.
   * @param {context} context of store
   */
  getCommonMetadata (context) {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_GP_SERVICES}sharedMetadata`,
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
    }).then(response => {
      context.commit({
        type: 'SET_METADATA',
        metadata: response.data
      })
      context.commit({
        type: 'SET_METADATA_INDUSTRIES',
        industries: response.data.industries
      })
      return response.data
    })
  },

  /**
   * @description Searches a term to retrieve a list of users that match with the term.
   * @param {context} context of the store.
   * @param {payload} searchTerm term for searching a user.
   */
  searchUser (context, payload) {
    const url = `${process.env.VUE_APP_SUGGESTIONS_SERVICE}suggestions/people?term=${payload.searchTerm}&top=100`
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: url
      }).then(response => {
        if (response.data && Array.isArray(response.data)) {
          resolve(response.data.map(person => {
            return { ...person, value: `${person.name} (${person.email})` }
          }))
        }
      }, error => {
        console.error(error)
        reject(error)
      })
    })
  },

  /**
   * @description Searches a term to retrieve a list of users that match with the term, including external users.
   * @param {context} context of the store.
   * @param {payload} searchTerm term for searching a user.
   */
  searchExternalUser (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_SUGGESTIONS_SERVICE}suggestions/names?term=${payload.searchTerm}`
      }).then(response => resolve(response.data),
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  },

  /**
   * @description Manages all the logic to upload a file to intel.
   * @param {context} context of the store.
   * @param {payload} file file to be uploaded.
   * @param {payload} tempId temporary id to identify the file.
   */
  uploadIntelFile (context, payload) {
    context.commit({
      type: 'ADD_FILE_TO_UPLOAD_LIST',
      file: payload.file,
      source: 'intelligence',
      tempId: payload.tempId,
      status: 'uploading',
      progress: 0
    })

    context.dispatch({
      type: 'getIntelSharePointUrl',
      fileName: payload.file.name,
      tempId: payload.tempId
    }).then(response => {
      context.commit({
        type: 'SET_TRANSACTION_ID',
        transactionId: response.transactionId
      })
      context.dispatch({
        type: 'uploadIntelFileToSharePoint',
        tempId: payload.tempId,
        contentId: response.contentInfo.contentId,
        file: payload.file,
        uploadSession: response.contentInfo.uploadSession
      })
    })
  },

  /**
   * @description Gets the url of the container to upload.
   * @param {context} context of the store.
   * @param {payload} fileName name of the file.
   */
  getIntelSharePointUrl (context, payload) {
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_INTEL_API}upload/start`,
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
      data: { fileName: payload.fileName }
    }).then(response => response.data).catch(() => {
      context.commit({ type: 'UPDATE_FILE_INFO', tempId: payload.tempId, info: { status: 'failed' } })
    })
  },

  /**
   *
   * @description Upload thumnail image
   * @param {context} context of the store.
   * @param {payload} itemId id of the item.
   * @param {payload} file selected file.
   * @param {payload} origin thumbnail origin
   * @param {payload} source form source
   */
  uploadThumbnail (context, payload) {
    const data = new FormData()
    data.append('thumbnail', payload.file)
    data.append('id', payload.itemId)
    data.append('transactionId', context.state.transactionId)
    if (payload.source === 'work') data.append('thumbnailOrigin', payload.origin)
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: payload.source === 'intelligence' ? `${process.env.VUE_APP_INTEL_API}thumbnail` : `${process.env.VUE_APP_WORK_SERVICE}thumbnail`,
        data: data,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then(
        (response) => {
          if (response) {
            if (payload.origin === 'upload') {
              for (var i in Array.from(Array(100))) context.commit({ type: 'UPDATE_THUMBNAIL_INFO', thumbnailInfo: { progress: ++i } })
              context.commit({ type: 'UPDATE_THUMBNAIL_INFO', thumbnailInfo: { status: 'completed' } })
            }
            context.commit({
              type: 'SET_THUMBNAIL_SELECTED',
              selectionType: payload.origin,
              selectedImage: payload.source === 'intelligence' ? response.data.thumbnailUrl : response.data
            })
            resolve(response)
          } else {
            if (payload.origin === 'upload') {
              context.commit({ type: 'UPDATE_THUMBNAIL_INFO', thumbnailInfo: { status: 'failed' } })
            }
          }
        },
        (error) => {
          if (payload.origin === 'upload') {
            context.commit({ type: 'UPDATE_THUMBNAIL_INFO', thumbnailInfo: { status: 'failed' } })
          }
          reject(error)
        }
      )
    })
  },

  /**
   *
   * @description Delete thumnail image
   * @param {context} context of the store.
   * @param {payload} itemId id of the item.
   */
  deleteThumbnail (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'DELETE',
        url: payload.source === 'intelligence' ? `${process.env.VUE_APP_INTEL_API}thumbnail/${payload.itemId}?transId=${context.state.transactionId}` : `${process.env.VUE_APP_WORK_SERVICE}thumbnail`,
        data: payload.source === 'intelligence' ? {} : { id: payload.itemId, transactionId: context.state.transactionId, thumbnailUrl: payload.thumbnailUrl },
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then(
        (response) => {
          if (response) {
            for (var i in Array.from(Array(100))) context.commit({ type: 'UPDATE_THUMBNAIL_INFO', thumbnailInfo: { progress: ++i } })
            context.commit({ type: 'UPDATE_THUMBNAIL_INFO', thumbnailInfo: { status: '' } })
            context.commit({
              type: 'SET_THUMBNAIL_SELECTED',
              selectionType: 'upload',
              selectedImage: ''
            })
            resolve(response)
          }
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Uploads the file in chunks.
   * @param {context} context of the store.
   * @param {payload} tempId temporary id to identify the file.
   * @param {payload} contentId id of file in intelligence.
   * @param {payload} file file to be uploaded.
   * @param {payload} uploadSession session to upload the file.
   */
  uploadIntelFileToSharePoint (context, payload) {
    const sourceToken = axios.CancelToken.source()
    const multipartChunkSize = 1 * 1024 * 1024 // 40MB,

    context.commit({
      type: 'UPDATE_FILE_INFO',
      tempId: payload.tempId,
      info: { ...{ id: payload.contentId }, ...{ sourceToken: sourceToken } }
    })

    helpers.getStreamFromFile(payload.file).then((fileInBytes) => {
      const numberOfChunks = Math.ceil(payload.file.size / multipartChunkSize)
      const chunks = []
      for (let currentChunk = 1; currentChunk <= numberOfChunks; currentChunk++) {
        const blob = helpers.getFileChunk(currentChunk, multipartChunkSize, payload.file)
        chunks.push({ file: blob, contentFile: fileInBytes.slice(blob.start, blob.end + 1) })
      }

      chunks.reduce((previousPromise, chunk, index) => {
        return previousPromise.then(() => {
          const fileUploadProgress = Math.ceil((index / chunks.length) * 100)
          if (fileUploadProgress < 100) {
            context.commit({
              type: 'UPDATE_FILE_INFO',
              id: payload.contentId,
              info: { progress: fileUploadProgress }
            })
          }
          return context.dispatch({
            type: 'uploadIntelChunk',
            chunk: chunk,
            uploadSession: payload.uploadSession,
            fileSize: payload.file.size,
            cancelToken: sourceToken.token,
            id: payload.contentId,
            tempId: payload.tempId
          })
        }).catch(() => {
          context.commit({ type: 'UPDATE_FILE_INFO', id: payload.contentId, info: { status: 'failed' } })
        })
      }, Promise.resolve()).then(response => {
        if (response) {
          context.commit({
            type: 'UPDATE_FILE_INFO',
            tempId: payload.tempId,
            info: { ...{ fileId: response.data.id } }
          })

          context.dispatch({
            type: 'finishIntelUpload',
            fileId: response.data.id,
            contentId: payload.contentId,
            fileName: response.data.name
          })
        }
      }).catch(() => {
        context.commit({ type: 'UPDATE_FILE_INFO', id: payload.contentId, info: { status: 'failed' } })
      })
    })
  },

  /**
   * @description Puts a chunk of the file in a given session.
   * @param {context} context of the store.
   * @param {payload} tempId temporary id to identify the file.
   * @param {payload} fileSize size of the file.
   * @param {payload} chunk chunk of the file to be uploaded.
   * @param {payload} cancelToken token to cancel the upload process.
   * @param {payload} id id of the intelligence file.
   * @param {payload} uploadSession session to upload the file.
   */
  uploadIntelChunk (context, payload) {
    return axios({
      method: 'PUT',
      url: `${payload.uploadSession}`,
      headers: {
        'Content-Range': `bytes ${payload.chunk.file.start}-${payload.chunk.file.end}/${payload.fileSize}`
      },
      data: payload.chunk.contentFile,
      cancelToken: payload.cancelToken
    }).catch(() => {
      context.commit({ type: 'UPDATE_FILE_INFO', id: payload.contentId, info: { status: 'failed' } })
    })
  },

  /**
   * @description Finishes the upload process of the intelligence file.
   * @param {context} context of the store.
   * @param {payload} fileId id of the intelligence file.
   * @param {payload} contentId id given by sharePoint container.
   * @param {payload} fileName name of file in sharePoint container.
   */
  finishIntelUpload (context, payload) {
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_INTEL_API}upload/finish`,
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
      data: { fileId: payload.fileId, contentId: payload.contentId, fileName: payload.fileName }
    }).then(() => {
      context.commit({
        type: 'UPDATE_FILE_INFO',
        id: payload.contentId,
        info: { status: 'completed', progress: 100 }
      })
    }).catch(() => {
      context.commit({ type: 'UPDATE_FILE_INFO', id: payload.contentId, info: { status: 'failed' } })
    })
  },

  /**
   * @description Cancels the upload process of an intelligence file
   * @param {context} context of the store.
   * @param {payload} tempId temporary id to identify the file.
   */
  cancelIntelFile (context, payload) {
    const file = context.state.uploadList.find(file => file.tempId === payload.tempId)
    if (!file) {
      return
    }
    if (file.status === 'uploading' && file.sourceToken) {
      file.sourceToken.cancel()
    }

    return axios({
      method: 'DELETE',
      url: `${process.env.VUE_APP_INTEL_API}upload/cancel`,
      data: {
        contentId: file.id,
        fileId: 0
      },
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
    }).then(() => {
      context.commit({
        type: 'DELETE_FILE',
        id: file.id
      })
    })
  },

  /**
   * @description Manages all the process to upload a work file.
   * @param {context} context of the store.
   * @param {payload} tempId temporary id to identify the file.
   * @param {payload} file file to be uploaded.
   * @param {payload} isSupportingFile flag to know if is the main file or a supporting one.
   * @param {payload} workItemId id of the work item (only needed if is supporting file).
   */
  uploadWorkFile (context, payload) {
    return new Promise((resolve) => {
      let fileInfo = null
      const multiple = payload.multiple || false
      context.commit({
        type: 'ADD_FILE_TO_UPLOAD_LIST',
        file: payload.file,
        source: 'work',
        tempId: payload.tempId,
        status: 'uploading',
        progress: 0,
        multiple: multiple
      })

      context.dispatch({
        type: 'getBlobWorkFile',
        file: payload.file,
        isSupportingFile: payload.isSupportingFile,
        workItemId: payload.workItemId,
        multiple: multiple
      }).then(response => {
        fileInfo = response.data.workItemInfo
        return context.dispatch({
          type: 'uploadWorkFileToBlob',
          file: payload.file,
          workItemInfo: fileInfo,
          tempId: payload.tempId,
          isSupportingFile: payload.isSupportingFile,
          multiple: multiple
        }).then(() => {
          return context.dispatch({
            type: 'completeUploadWorkFile',
            fileInfo: fileInfo,
            isSupportingFile: payload.isSupportingFile,
            multiple: multiple
          }).then(() => {
            context.commit({
              type: 'UPDATE_FILE_INFO',
              id: payload.isSupportingFile ? payload.tempId : fileInfo.workItemId,
              info: { status: 'completed', progress: 100 }
            })
            if (payload.isSupportingFile && multiple) {
              context.commit({
                type: 'SET_SUPPORTING_FILES_IN_PROCESS',
                add: false
              })
            }
            resolve(true)
          }).catch(() => {
            context.commit({ type: 'UPDATE_FILE_INFO', id: payload.isSupportingFile ? payload.tempId : fileInfo.workItemId, info: { status: 'failed' } })
            resolve(false)
          })
        }).catch((err) => {
          if (err.toString().indexOf('AbortError') === -1) {
            context.commit({ type: 'UPDATE_FILE_INFO', id: payload.isSupportingFile ? payload.tempId : fileInfo.workItemId, info: { status: 'failed' } })
            resolve(false)
          }
        })
      }).catch((err) => {
        console.log(err)
        context.commit({ type: 'UPDATE_FILE_INFO', tempId: payload.tempId, info: { status: 'failed' } })
        resolve(false)
      })
    })
  },

  /**
   * @description Gets the blob to upload the file.
   * @param {context} context of the store.
   * @param {payload} file file to be uploaded.
   * @param {payload} isSupportingFile flag to know if is the main file or a supporting one.
   * @param {payload} workItemId id of the work item (only needed if is supporting file).
   */
  getBlobWorkFile (context, payload) {
    let body = [{
      fileName: payload.file.name,
      fileSize: payload.file.size,
      fileMimeType: payload.file.type,
      order: 1,
      main: !payload.isSupportingFile
    }]

    if (payload.isSupportingFile) {
      body = {
        files: body,
        workItemId: payload.workItemId,
        transactionId: context.state.transactionId
      }
    }

    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_WORK_SERVICE}${payload.isSupportingFile ? (payload.multiple ? 'supportingFiles' : 'supportingFiles/greycom') : 'upload'}/start`,
      data: body,
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
    })
  },

  /**
   * @description Upload the work file into the blob.
   * @param {context} context of the store.
   * @param {payload} tempId temporary id to identify the file.
   * @param {payload} file file to be uploaded.
   * @param {payload} isSupportingFile flag to know if is the main file or a supporting one.
   * @param {payload} workItemInfo object with the information of the blob.
   */
  uploadWorkFileToBlob (context, payload) {
    const fileInfo = payload.workItemInfo
    const asset = payload.isSupportingFile ? fileInfo.files[0] : fileInfo.assets[0]
    const abortSignal = new AbortController()
    let url = ''
    if (payload.isSupportingFile) {
      url = asset.containerSasUrl.replace('?sv', `/${asset.storageFileName}?sv`)
    } else {
      url = asset.containerSasUrl.replace('original', `original/${asset.storageFileName}`)
    }
    const maxUploadSize = 1 * 1024 * 1024 // 40MB,
    const fileId = payload.isSupportingFile ? payload.tempId : fileInfo.workItemId
    const supportingFileId = payload.isSupportingFile ? fileInfo.files[0].order : ''

    if (!payload.isSupportingFile) {
      context.commit({
        type: 'SET_TRANSACTION_ID',
        transactionId: fileInfo.transactionId
      })
    }

    if (payload.isSupportingFile && !payload.multiple) {
      context.commit({
        type: 'SET_SUPPORTING_EXTRA_IMAGE_ID',
        supportingExtraImageId: supportingFileId
      })
    }

    if (payload.isSupportingFile && payload.multiple) {
      context.commit({
        type: 'SET_SUPPORTING_FILES_IN_PROCESS',
        add: true
      })
    }

    context.commit({
      type: 'UPDATE_FILE_INFO',
      tempId: payload.tempId,
      info: { ...asset, ...{ id: fileId, workItemId: fileInfo.workItemId, supportingFileId: supportingFileId }, ...{ abortSignal: abortSignal } }
    })

    const blockBlobClient = new BlockBlobClient(url, newPipeline(new AnonymousCredential()))
    const requestConfiguration = {
      abortSignal: abortSignal.signal,
      blockSize: maxUploadSize,
      maxSingleShotSize: maxUploadSize,
      concurrency: 10,
      onProgress: (progress) => {
        const fileUploadProgress = Math.round((progress.loadedBytes * 100) / payload.file.size)
        context.commit({
          type: 'UPDATE_FILE_INFO',
          id: payload.isSupportingFile ? payload.tempId : fileInfo.workItemId,
          info: { progress: Math.min(99, fileUploadProgress) }
        })
      }
    }

    return blockBlobClient.uploadBrowserData(payload.file, requestConfiguration)
  },

  /**
   * @description Finishes the process of uploading a work file.
   * @param {context} context of the store.
   * @param {payload} isSupportingFile flag to know if is the main file or a supporting one.
   * @param {payload} id id of the work item.
   */
  completeUploadWorkFile (context, payload) {
    const data = payload.isSupportingFile
      ? { workItemId: payload.fileInfo.workItemId, supportingFileId: payload.fileInfo.files[0].order, transactionId: context.state.transactionId }
      : { workItemId: payload.fileInfo.workItemId, assetId: 1 }
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_WORK_SERVICE}${payload.isSupportingFile ? (payload.multiple ? 'supportingFiles' : 'supportingFiles/greycom/') : 'upload'}/complete`,
      data: data,
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
    })
  },

  /**
   * @description Removes a work file
   * @param {context} context of the store.
   * @param {payload} tempId temporary id to identify the file.
   * @param {payload} isSupportingFile flag to know if is the main file or a supporting one.
   * @param {payload} workItemId id of the work item.
   */
  removeWorkFile (context, payload) {
    return new Promise((resolve, reject) => {
      const file = context.state.uploadList.find(file => file.tempId === payload.tempId)
      if (!file && !payload.isEdition) {
        return
      }
      if (!payload.isEdition && file.status === 'uploading' && file.abortSignal && file.abortSignal.abort) {
        file.abortSignal.abort()
      }

      return axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_WORK_SERVICE}${payload.isSupportingFile ? (payload.multiple ? 'supportingFiles/remove' : 'supportingFiles/greycom/cancel') : 'upload/cancel'}`,
        data: payload.isSupportingFile ? { workItemId: payload.workItemId, supportingFileId: payload.multiple ? file.supportingFileId : context.state.supportingExtraImageId, transactionId: context.state.transactionId } : { workItemId: file.id, assetId: 1 },
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then((response) => {
        if (!payload.isEdition) {
          context.commit({
            type: 'DELETE_FILE',
            id: payload.isSupportingFile ? payload.tempId : file.tempId
          })
          resolve(response)
        }
      })
    })
  },

  /**
   * @description Get the metadata of work form.
   * @param {context} context of the store.
   */
  getWorkMetadata (context) {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_WORK_SERVICE}metadata`,
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
    }).then((response) => {
      context.commit({
        type: 'SET_WORK_METADATA',
        metadata: response.data
      })
    })
  },

  /**
   * @description Get tags.
   * @param {payload} searchTerm term for searching a user.
   */
  searchTag (context, payload) {
    const url = `${process.env.VUE_APP_SUGGESTIONS_SERVICE}Suggestions/tags?term=${payload.searchTerm}&$top=100`
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: url
      }).then(response => {
        if (response.data && Array.isArray(response.data)) {
          resolve(response.data.map(tag => {
            return { id: tag, value: tag }
          }))
        }
      }, error => {
        console.error(error)
        reject(error)
      })
    })
  },

  /**
   * @description Submits the info of the work form.
   * @param {context} context of store.
   * @param {payload} body object with all the information of the work item.
   */
  submitWorkItem (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_WORK_SERVICE}workitem`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        },
        data: payload.body
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },

  /**
   * @description Submits the info of the intelligence form.
   * @param {context} context of store.
   * @param {payload} body object with all the information of the intelligence item.
   */
  submitIntelItem (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_INTEL_API}Content/Submit`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
        data: payload.body
      }).then((response) => (resolve(response)), (error) => (reject(error)))
    })
  },

  /**
   * @description Gets the information of a work item.
   * @param {context} context of store.
   * @param {payload} id id of the item to get the info.
   */
  getWorkItem (context, payload) {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_WORK_SERVICE}workitem/${payload.id}/edit`,
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
    }).then((response) => response.data)
  },

  /**
   * @description Gets the information of an intelligence item.
   * @param {context} context of store.
   * @param {payload} id id of the item to get the info.
   */
  getIntelItem (context, payload) {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_INTEL_API}Content/${payload.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
    }).then((response) => response.data)
  },

  /**
   *
   * @description Save url associated to selected thumbnail by default
   * @param {context} context of the store.
   * @param {payload} itemId id of the item.
   * @param {payload} thumbnailUrl Url of the selected image.
   * @param {payload} source the form source
   */
  saveDefaultThumbnail (context, payload) {
    const body = {
      id: payload.itemId,
      thumbnailUrl: payload.thumbnailUrl,
      transactionId: context.state.transactionId,
      ...(payload.source === 'work' && { thumbnailOrigin: 'autogenerated' })
    }
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: payload.source === 'intelligence' ? `${process.env.VUE_APP_INTEL_API}thumbnail/autoGenerated` : `${process.env.VUE_APP_WORK_SERVICE}thumbnail/autoGenerated`,
        data: body,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then(
        (response) => {
          context.commit({
            type: 'SET_THUMBNAIL_SELECTED',
            selectedImage: payload.source === 'intelligence' ? response.data.thumbnailUrl : response.data
          })
          resolve(response)
        },
        (error) => reject(error)
      )
    })
  },

  /**
   *
   * @description Save url associated to selected thumbnail by default
   * @param {context} context of the store.
   * @param {payload} itemId id of the item.
   * @param {payload} file Blob file from picture taken witn canvas.
   */
  saveCapturedThumbnail (context, payload) {
    const data = new FormData()
    data.append('thumbnail', payload.capturedThumbnail)
    data.append('transactionId', context.state.transactionId)
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_INTEL_API}thumbnail/${payload.itemId}`,
        data: data,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then(
        (response) => {
          context.commit({
            type: 'SET_THUMBNAIL_SELECTED',
            selectedImage: response.data.thumbnailUrl
          })
          resolve(response)
        },
        (error) => reject(error)
      )
    })
  }
}

const getters = {
  /**
   * @description Returns a file searched by a given tempId.
   * @param {string} tempId temporary id to search the file.
   */
  getFileById: (state) => (tempId) => {
    if (state.uploadList && tempId) {
      const item = state.uploadList.find(file => file.tempId === tempId)
      return item || {}
    }
    return {}
  }
}

const helpers = {
  /**
   * @description Returns a tree object builded with the given params.
   * @param {string} label label of the tree.
   * @param {boolean} isSelected indicate if tree will appear as selected.
   * @param {boolean} isIndeterminate indicate if tree will have an indeterminate state.
   * @param {boolean} isVisible indicate if tree will be visible.
   */
  getTreeObj (label, isSelected = false, isIndeterminate = false, isVisible = true) {
    return {
      isIndeterminate: isIndeterminate,
      isSelected: isSelected,
      isVisible: isVisible,
      label: label,
      labelSearch: label.toLowerCase()
    }
  },

  /**
   * @description Formats the size of a file.
   * @param {number} sizeOnBytes size to format given in bytes.
   */
  formatSizeFile (sizeOnBytes) {
    const suffixes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (sizeOnBytes === 0) {
      return '0 Bytes'
    }
    const power = parseInt(Math.floor(Math.log(sizeOnBytes) / Math.log(1024)))
    return `${parseFloat(sizeOnBytes / Math.pow(1024, power)).toFixed(2)} ${suffixes[power]}`
  },

  /**
   * @description Creates a chunks from the file.
   * @param {number} part Number of the current chunk.
   * @param {number} multipartChunkSize size of the chunk.
   * @param {file} file file to be chunked.
   */
  getFileChunk (part, multipartChunkSize, file) {
    const start = (part - 1) * multipartChunkSize
    const end = Math.min((start + multipartChunkSize), file.size)
    return ({ start: start, end: end - 1, blob: file.slice(start, end) })
  },

  /**
   * @description Gets an stream from a file.
   * @param {file} file to be converted into stream.
   */
  getStreamFromFile (file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = () => {
        resolve(new Uint8Array(reader.result))
      }
    })
  }
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
