import axios from 'axios'
import * as types from './mutationTypes'
import router from '@/router'

const state = {
  formState: {},
  filesUploaded: [],
  showNotificationOnWidget: false
}

const mutations = {
  /**
   * @description Updates metadata object for work dropdown.
   * @param {state} state of store.
   * @param {payload} metadata object with the info of the metadata.
   */
  [types.SET_WORK_METADATA] (state, payload) {
    state.formState.metadata = Object.assign({}, state.formState.metadata, payload.metadata)
  }
}

const actions = {
  /**
   * @description Get file preview by id
   * @param {context} context
   * @param {payload} id contain the item id
   */
  getWorkFilePreview (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_WORK_SERVICE}workitem/${payload.id}/Asset/1/Download`,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Get detail of work item by id
   * @param {payload} id Card id
   */
  getDetailWorkById (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_WORK_SERVICE}workitem/${payload.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          router.push({ name: 'pageNotFound' })
          reject(error)
        }
      )
    })
  },

  /**
   * @description Get flag that indicate if the current user can delete the item
   * @param {payload} id Item id
   */
  canDeleteWorkItem (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_WORK_SERVICE}workitem/${payload.id}/canedit`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Delete the selected item
   * @param {payload} id Item id to delete
   */
  deleteWorkItem (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_WORK_SERVICE}workitem/${payload.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}

const getters = {

  /**
   * @description Returns an array of visible filters based on category selected.
   */
  visibleFiles: (state) => {
    if (state.filesUploaded) {
      return state.filesUploaded.filter(file => file.visibleOnWidget)
    }
    return []
  },

  /**
   * @description Returns a flag to know if all files have already been upload successfully.
   */
  canCloseWidgetFiles: (state) => {
    const visibleFiles = state.filesUploaded.filter(file => file.visibleOnWidget)
    if (visibleFiles && visibleFiles.length) {
      return visibleFiles.length === visibleFiles.filter(file => file.status === 'success').length
    }
    return true
  },

  /**
   * @description Returns a flag to indicate if are uploading processes at the moment.
   */
  areUploadingProcesses () {
    return state.filesUploaded.some(file => file.status === 'uploading')
  }
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
