import { createRouter, createWebHistory } from 'vue-router'
import VueJwtDecode from 'vue-jwt-decode'
import { trackRouter } from 'vue-gtag-next'
import store from '../store'
import * as constants from '@/store/mutationTypes'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/home'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      store.commit({
        type: 'SET_CURRENT_SITE',
        site: 'SEARCH_SITE'
      })
      next()
    }
  },
  {
    path: '/resultsSearch',
    name: 'resultsSearch',
    component: () => import('../views/resultsSearch/resultsSearch'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      store.commit({
        type: 'SET_CURRENT_SITE',
        site: 'SEARCH_SITE'
      })
      if (to.query.gv) {
        store.commit({
          type: 'SET_GRID_VIEW',
          isGridView: to.query.gv === 'true'
        })
      }
      if (Object.keys(to.query).length === 0) {
        next('/resultsSearch?q=&t=8&k=1&s=All%20sites&f=')
      } else {
        next()
      }
    }
  },
  {
    path: '/resultsIntelligence',
    name: 'resultsIntelligence',
    component: () => import('../views/resultsIntelligence/resultsIntelligence'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      store.commit({
        type: 'SET_CURRENT_SITE',
        site: 'INTELL_SITE'
      })
      if (to.query.gv) {
        store.commit({
          type: 'SET_GRID_VIEW',
          isGridView: to.query.gv === 'true'
        })
      }
      document.title = 'Grey Intelligence'
      if (Object.keys(to.query).length === 0) {
        next('/resultsIntelligence?q=&t=8&k=1&s=Intelligence&f=')
      } else {
        next()
      }
    }
  },
  {
    path: '/resultsWork',
    name: 'resultsWork',
    component: () => import('../views/resultsWork/resultsWork'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      store.commit({
        type: 'SET_CURRENT_SITE',
        site: constants.WORK_SITE
      })
      if (to.query.gv) {
        store.commit({
          type: 'SET_GRID_VIEW',
          isGridView: to.query.gv === 'true'
        })
      }
      document.title = 'Grey Work'
      if (Object.keys(to.query).length === 0) {
        next('/resultsWork?q=&t=8&k=1&s=Work&f=')
      } else {
        next()
      }
    }
  },
  {
    path: '/resultsSalesForce/:idOpportunity',
    name: 'resultsSalesForce',
    component: () => import('../views/resultsSalesForce/resultsSalesForce'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      store.commit({
        type: 'SET_CURRENT_SITE',
        site: constants.SALES_FORCE_SITE
      })
      if (Object.keys(to.query).length === 0) {
        next(`/resultsSalesForce/${to.params.idOpportunity}?q=&t=8&k=1&s=Work&f=`)
      } else if (Object.keys(to.query).length === 1 && to.query.s) {
        next(`/resultsSalesForce/${to.params.idOpportunity}?q=&t=8&k=1&s=${to.query.s}&f=`)
      } else {
        next()
      }
    }
  },
  {
    path: '/detail/:idCard',
    name: 'detailPage',
    component: () => import('../views/detailPage/detailPage'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      document.title = 'Grey Intelligence'
      next()
    }
  },
  {
    path: '/detailWork/:idCard',
    name: 'detailWorkPage',
    component: () => import('../views/detailWorkPage/detailWorkPage'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      document.title = 'Grey Work'
      next()
    }
  },
  {
    path: '/detailCollection/:idCard',
    name: 'detailCollectionPage',
    component: () => import('../views/detailCollectionPage/detailCollectionPage'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      document.title = 'Grey Collection'
      if (Object.keys(to.query).length === 0) {
        next(`/detailCollection/${to.params.idCard}?t=48&k=1`)
      } else if (to.query.sharingToken) {
        store.commit({
          type: 'SET_SHARING_TOKEN',
          sharingToken: to.query.sharingToken
        })
        next(`/detailCollection/${to.params.idCard}?t=48&k=1`)
      } else {
        next()
      }
    }
  },
  {
    path: '/savedSearches',
    name: 'savedSearches',
    component: () => import('../views/savedSearches/savedSearches'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next('/savedSearches?t=12&k=1')
      } else {
        next()
      }
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/notifications/notifications'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next('/notifications?t=12&k=1')
      } else {
        next()
      }
    }
  },
  {
    path: '/uploadFile/:itemId?',
    name: 'uploadFile',
    component: () => import('../views/uploadFile/uploadFile'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (VueJwtDecode.decode(localStorage.getItem('sessionToken')).role.includes('Internal')) {
        next()
      } else {
        next('pageNotFound')
      }
    }
  },
  {
    path: '/myRequest',
    name: 'myRequest',
    component: () => import('../views/myRequest/myRequest'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next('/myRequest?t=12&k=1')
      } else {
        next()
      }
    }
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('../views/collections/collections'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next('/collections?s=shared')
      } else {
        next()
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    component: () => import('../views/pageNotFound/pageNotFound'),
    beforeEnter: (to, from, next) => {
      store.commit({
        type: 'SHOWFOOTER',
        showFooter: false
      })
      next()
    }
  },
  {
    path: '/collectionNotFound',
    name: 'collectionNotFound',
    component: () => import('../views/collectionNotFound/collectionNotFound')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
trackRouter(router)
/**
 * @description Validate if token is valid always when the user get ino any route view
 */
router.beforeEach((to, from, next) => {
  const LOGIN_BASE_URL = process.env.VUE_APP_LOGIN_SERVICE_URL
  if (!localStorage.getItem('sessionToken')) {
    const queryParams = window.location.search.replace(/&/g, '$26')
    window.location.href = `${LOGIN_BASE_URL}login?site=${window.location.origin}&state=${window.location.pathname}${queryParams}`
    localStorage.setItem('sessionToken', 'null')
  } else {
    let jwtDecode = ''
    const timeNow = Date.now().valueOf() / 1000
    localStorage.setItem('sessionToken', to.query.token ? to.query.token : localStorage.getItem('sessionToken'))
    jwtDecode = VueJwtDecode.decode(localStorage.getItem('sessionToken'))
    if (!jwtDecode || jwtDecode.exp < timeNow) {
      localStorage.clear()
      localStorage.setItem('sessionToken', 'null')
      const queryParams = window.location.search.replace(/&/g, '$26')
      window.location.href = `${LOGIN_BASE_URL}login?site=${window.location.origin}&state=${window.location.pathname}${queryParams}`
    } else {
      next(vm => {
      })
      if (to.query.state !== undefined) {
        const queryState = to.query.state.replace(/\$26/g, '&')
        window.location.href = `${window.location.origin}${queryState}`
      }
    }
  }
})

export default router
