import axios from 'axios'
import * as types from './mutationTypes'

const state = {
  collectionsAbortSignal: null
}

const mutations = {
  /**
   * @description Sets signal to abort collections request
   * @param {state} state of store.
   * @param {payload} signal signal object
   */
  [types.SET_COLLECTIONS_ABORT_SIGNAL] (state, payload) {
    state.collectionsAbortSignal = payload.signal
  }
}

const actions = {

  /**
   * @description Remove collection by id
   * @param {context} Context of store
   * @param {payload} collectionId
   */
  deleteCollectionById (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/${payload.collectionId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
  * @description Get Collection item as a csv file
  * @param {context} Context of store
  * @param {payload} collectionId
  */
  getCollectionItemsAsCsv (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/${payload.collectionId}/csv`,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'PeopleFinderCollection.csv')
          document.querySelector('.containerCollectionOptions').appendChild(fileLink)

          fileLink.click()
          document.querySelector('.containerCollectionOptions').removeChild(fileLink)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
  * @description Get Collection Members Email (Only for People's items inside collection)
  * @param {context} Context of store
  * @param {payload} collectionId
  */
  getCollectionMembers (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/${payload.collectionId}/MemberEmails`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          let emailList = ''
          response.data.map((item, key) => {
            emailList += (key < response.data.length - 1) ? `${item};` : item
            if (key === response.data.length - 1) {
              window.location.href = `mailto:${emailList}`
            }
          })
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
  * @description Get the share link of an specific collection
  * @param {context} Context of store
  * @param {payload} collectionId
  */
  getCollectionShareLink (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/${payload.collectionId}/Share/Link`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
  * @description Request to share a collection with different users via email
  * @param {context} Context of store
  * @param {payload} collectionId
  * @param {payload} emailList
  */
  shareCollectionByEmail (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'PATCH',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/${payload.collectionId}/Share`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        },
        data: {
          Items: payload.emailList
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
  * @description Request to delete collection item by id
  * @param {context} Context of store
  * @param {payload} collectionId
  * @param {payload} itemCollectionId
  */
  deleteItemCollectionById (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/${payload.collectionId}/Items/${payload.itemCollectionId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Request to select a collection thumbnail from an item
   * @param {context} context of store
   * @param {payload} collectionId collection to change thumbnail
   * @param {payload} itemId item selected to be collection thumbnail
   */
  selectCollectionThumbnail (context, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_GP_SERVICES}collections/thumbnail`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        },
        data: {
          collectionId: payload.collectionId,
          itemId: payload.itemId
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Get collections owned by user
   * @param {context} context of store
   */
  getMineCollections (context, payload) {
    return new Promise((resolve, reject) => {
      const signal = axios.CancelToken.source()
      context.commit({
        type: 'SET_COLLECTIONS_ABORT_SIGNAL',
        signal
      })
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/Mine`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
          'Cache-Control': 'no-cache, max-age=0, no-store'
        },
        cancelToken: signal.token
      }).then(
        (response) => {
          if (response) resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Get collections shared with user
   * @param {context} context of store
   */
  getSharedWithMeCollections (context, payload) {
    return new Promise((resolve, reject) => {
      const signal = axios.CancelToken.source()
      context.commit({
        type: 'SET_COLLECTIONS_ABORT_SIGNAL',
        signal
      })
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/SharedWithMe`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        },
        cancelToken: signal.token
      }).then(
        (response) => {
          if (response) resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Cancels collections list request
   */
  cancelCollectionsRequest (context, payload) {
    state.collectionsAbortSignal.cancel()
  }
}

export default {
  namespaced: true,
  actions: actions,
  state: state,
  mutations: mutations
}
