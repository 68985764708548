import store from '../store/index'

/**
 * Utility functions to track desired events for Azure Search Analytics.
*/

/**
 * @description Initialize the Telemetry client to track the events
 * @param trackingKey Unique key to identify application
*/
/* eslint-disable */
export function initAzureAnalytics(trackingKey) {
  var appInsights=window.appInsights||function(config){function r(config){t[config]=function(){var i=arguments;t.queue.push(function(){t[config].apply(t,i)})}}var t={config:config},u=document,e=window,o="script",s=u.createElement(o),i,f;s.src=config.url||"//az416426.vo.msecnd.net/scripts/a/ai.0.js";u.getElementsByTagName(o)[0].parentNode.appendChild(s);try{t.cookie=u.cookie}catch(h){}for(t.queue=[],i=["Event","Exception","Metric","PageView","Trace","Dependency"];i.length;)r("track"+i.pop());return r("setAuthenticatedUserContext"),r("clearAuthenticatedUserContext"),config.disableExceptionTracking||(i="onerror",r("_"+i),f=e[i],e[i]=function(config,r,u,e,o){var s=f&&f(config,r,u,e,o);return s!==!0&&t["_"+i](config,r,u,e,o),s}),t}
  ({
  instrumentationKey: trackingKey
  });
  window.appInsights=appInsights;
}
/* eslint-enable */

/**
 * @description Track a new query search
 * @param searchId ID of the current search
 * @param keyword Query keywords used by the user
 * @param resultCount Number of results the search return
*/
export function trackQuery (searchId, keyword, resultCount) {
  window.appInsights.trackEvent('Search', {
    SearchServiceName: `grey-search-engine-${process.env.VUE_APP_NODE_ENV}`,
    SearchId: searchId,
    IndexName: `grey-search-${process.env.VUE_APP_NODE_ENV}`,
    QueryTerms: keyword,
    ResultCount: resultCount,
    ScoringProfile: ''
  })
}

/**
 * @description Track a clicked result
 * @param searchId ID of the current search
 * @param documentId ID of the clicked result
 * @param index local position of the clicked result
*/
export function trackDocument (searchId, documentId, index) {
  const rank = ((store.state.pagination.page - 1) * store.state.dataCards.value.length + index)
  window.appInsights.trackEvent('Click', {
    SearchServiceName: `grey-search-engine-${process.env.VUE_APP_NODE_ENV}`,
    SearchId: searchId,
    ClickedDocId: documentId,
    Rank: rank
  })
}

/**
 * @description Track movement in the result pages
 * @param searchId ID of the current search
 * @param page Page number the user navigated to
*/
export function trackPagination (searchId, page) {
  window.appInsights.trackEvent('Click', {
    SearchServiceName: `grey-search-engine-${process.env.VUE_APP_NODE_ENV}`,
    SearchId: searchId,
    ClickedDocId: `page_${page}`,
    Rank: page
  })
}
